import clsx from 'clsx';
import { ModalOverlay } from 'components';
import AllIncomingCallNotification from 'components/IncomingCallNotification/AllIncomingCallNotification';
import NotificationsProvider from 'notifications/context/NotificationsState';
import React from 'react';
import { isMobile, isSafari } from 'react-device-detect';
import { connect } from 'react-redux';
import { IntercomProvider } from 'react-use-intercom';
import getStoreConfig from 'util/getStoreConfig';
import normalize from 'util/normalize';
import { v4 as uuidv4 } from 'uuid';
import { StatusBar } from '../../components/StatusBar';
import CallNotificationStatusBar from 'components/CallNotificationStatusBar/CallNotificationStatusBar';
import VirtualKeyboard from '../../components/VirtualKeyboard';
import { VirtualKeyboardProvider } from '../../context/VirtualKeyboardContext';
import { _firestore } from '../../firebase';
import AppRoutes from '../../routes';
import InitializeArg from '../../state';
import { fetchStoreWebsiteLoaderData } from '../../store/storeWebsiteLoaderData/storeWebsiteLoaderDataActions';
import { isMacOSOrWindowsDevice } from '../../util/isMacOSOrWindowsDevice';
import { authContext } from '../SignIn/useProvideAuth';
import {
  appLoaded,
  loadCategories,
  loadCloseDates,
  loadDrawers,
  loadMenuItemModifiers,
  loadMenuItems,
  loadMenuItemSizes,
  loadMenuItemSubModifiers,
  loadOrderSetup,
  loadPinpads,
  loadPosVersion,
  loadProductSetup,
  loadPublicHolidays,
  loadRefundReasons,
  loadShifts,
  loadSpecials,
  loadSoldOutItems,
  loadSuburbs,
  loadTerminalSettings,
  setOrderSetup,
  setScreenSizes,
  updateStoreConfig,
  updateStripePayments,
  setAcceptedCall,
  resetCurrentOrder,
  setMenuItemSizes,
  setMenuItemSubModifiers,
  loadWindcavePinpads,
  loadWindcaveUser,
  loadCreditCardsCharges,
} from './action';
import { fetchStoreConfig, geConfigs } from './apis/fetchStoreConfig';
import { fetchMenuItemData } from './apis/fetchMenuItemData';
import { loadPaymentTypes } from './thunks';
import { updateCallInfo } from '../../components/IncomingCallNotification/IncomingCallNotification.service';
import { windcaveCountriesIdentifier } from '../../util/utils';

class Dashboard extends React.PureComponent {
  static contextType = authContext;

  constructor(props) {
    super(props);
    this.state = {
      windowSizes: {
        width: '',
        height: '',
      },
      homeClass: false,
      initialArgs: InitializeArg,
    };
  }

  async getUpdatedStoreConfig() {
    const storeConfig = getStoreConfig() ?? this.props.storeConfig;

    if (storeConfig) {
      const updatedStoreConfig = await fetchStoreConfig(storeConfig.storeId);
      const updatedConfigs = await geConfigs(storeConfig.restaurantId);
      this.props.fetchStoreWebsiteLoaderData(storeConfig.storeId);

      if (updatedStoreConfig && updatedStoreConfig?.storeId) {
        if (process.env.NODE_ENV === 'production') {
          window?.clarity(
            'set',
            'Restaurant Id',
            updatedStoreConfig?.restaurantId
          );
          window?.clarity('set', 'Store Id', updatedStoreConfig?.storeId);
          window?.clarity('set', 'Name', updatedStoreConfig?.name);
          window?.clarity('set', 'Address', updatedStoreConfig?.address);
        }

        localStorage.setItem('storeConfig', JSON.stringify(updatedStoreConfig));
        localStorage.setItem('configs', JSON.stringify(updatedConfigs));

        if (updatedStoreConfig) {
          this.props.updateStoreConfig(updatedStoreConfig, updatedConfigs);
        }
      }
    }
  }

  storeConfigInLSAndTriggerInit() {
    const storeConfigLS = localStorage.getItem('storeConfig');
    const configsLS = localStorage.getItem('configs');
    const isAuthCodeRequiredLS = localStorage.getItem('isAuthCodeRequired');
    const printCustomerReceiptAutomaticallyOnPOS = localStorage.getItem(
      'printCustomerReceiptAutomaticallyOnPOS'
    );
    const stripeCardPayments = localStorage.getItem('stripeCardPayments')
      ? JSON.parse(localStorage.getItem('stripeCardPayments'))
      : false;
    const uuid = localStorage.getItem('uuidForTerminalSettings');
    if (!uuid) {
      localStorage.setItem('uuidForTerminalSettings', uuidv4());
    }
    const storeConfig = storeConfigLS
      ? JSON.parse(storeConfigLS)
      : this.props.storeConfig;
    const configs = configsLS ? JSON.parse(configsLS) : this.props.configsLS;

    const { isAuthDisabledForPOS: isAuthDisabledForPOSLS } =
      isAuthCodeRequiredLS ? JSON.parse(isAuthCodeRequiredLS) : false;

    const {
      printCustomerReceiptAutomaticallyOnPOS:
        printCustomerReceiptAutomaticallyOnPOSLS,
    } = printCustomerReceiptAutomaticallyOnPOS
      ? JSON.parse(printCustomerReceiptAutomaticallyOnPOS)
      : false;

    if (
      typeof isAuthDisabledForPOSLS === 'boolean' ||
      typeof printCustomerReceiptAutomaticallyOnPOSLS === 'boolean'
    ) {
      this.props.setOrderSetup({
        isAuthDisabledForPOS: isAuthDisabledForPOSLS,
        printCustomerReceiptAutomaticallyOnPOS:
          printCustomerReceiptAutomaticallyOnPOSLS,
      });
    }

    if (storeConfig?.storeId && storeConfig?.restaurantId) {
      this.props.loadPublicHolidays(
        storeConfig?.timeZone,
        storeConfig?.stateIdentifier,
        storeConfig?.countryIdentifier,
        storeConfig?.storeId
      );
      this.props.loadRefundReasons();
      this.props.loadOrderSetup(storeConfig?.storeId);
      this.props.loadProductSetup(storeConfig?.storeId);
      // this.props.loadCloseDates(storeConfig?.storeId);
      this.props.loadShifts(storeConfig?.storeId);
      this.props.loadSuburbs(storeConfig?.restaurantId);
      this.props.loadCreditCardsCharges(storeConfig?.storeId);
      this.props.loadCategories(storeConfig?.storeId);
      this.props.loadMenuItems(
        storeConfig?.storeId,
        this.props?.firebasePersistenceLogging
      );
      this.props.loadSpecials(storeConfig?.storeId);
      this.props.loadSoldOutItems(storeConfig?.storeId);
      !this.props.fetchMinifiedMenuItemSizesAndSubModifiers &&
        this.props.loadMenuItemSizes(storeConfig?.storeId);
      !this.props.fetchMinifiedMenuItemSizesAndSubModifiers &&
        this.props.loadMenuItemModifiers(storeConfig?.storeId);
      !this.props.fetchMinifiedMenuItemSizesAndSubModifiers &&
        this.props.loadMenuItemSubModifiers(storeConfig?.storeId);
      this.loadDineInCourses(storeConfig?.restaurantId, storeConfig?.storeId);
      this.props.loadPinpads(storeConfig?.storeId);
      this.props.loadDrawers(storeConfig?.storeId);
      this.props.loadTerminalSettings(storeConfig?.storeId);
      {
        windcaveCountriesIdentifier?.includes(storeConfig?.countryIdentifier) &&
          this.props.loadWindcavePinpads(storeConfig?.storeId);
      }
      {
        windcaveCountriesIdentifier?.includes(storeConfig?.countryIdentifier) &&
          this.props.loadWindcaveUser(storeConfig?.storeId);
      }
      this.props.loadPaymentTypes(storeConfig?.storeId);
      this.props.updateStripePayments(stripeCardPayments);
    }

    if (storeConfig && !this.props.storeConfig?.storeId) {
      this.props.updateStoreConfig(storeConfig, configs);
    }

    this.props.loadPosVersion();

    /**
     * the following commented code is partially faulty
     * don't uncomment it.
     */

    // else if (this.context) {
    //   // someone has cleared off the localstorage while user is still logged in
    //   // we have to go through the whole process of sign in because that's how
    //   // we get the value of store Config form. It's a sequential process
    //   // 1. /login/ post end point with correct auth code => we get email and password + other data
    //   // => we programmatically log the person in and update the storeConfig in both localstorage and redux

    //   // if someone cleared localstorage, while the user is still logged in,
    //   // we don't have additional process to get the data we got from /login/
    //   // end point, hence we have to log the user out to force the user to put
    //   // the auth code again so that we can repeat our process.
    //   _auth.signOut();
    // }
  }

  componentDidMount() {
    const storeConfig = JSON.parse(localStorage.getItem('storeConfig'));
    if (
      this.props?.fetchMinifiedMenuItemSizesAndSubModifiers &&
      storeConfig?.storeId
    ) {
      fetchMenuItemData(storeConfig?.storeId || '').then(
        ({ menuItemSizes, menuItemSubModifiers }) => {
          this.props.setMenuItemSizes(menuItemSizes);
          this.props.setMenuItemSubModifiers(menuItemSubModifiers);
        }
      );
    }
    this.storeConfigInLSAndTriggerInit();
  }

  loadDineInCourses = async (restaurantId, storeId) => {
    if (!restaurantId) {
      restaurantId = this.props.storeConfig?.restaurantId;
    }
    if (!storeId) {
      storeId = this.props.storeConfig?.storeId;
    }

    const response = await _firestore
      .collection('DineInCourses')
      .where('storeId', '==', storeId)
      .where('restaurantId', '==', restaurantId)
      .get();

    const dineInCourses = normalize(
      response.docs.map((doc) => ({ ...doc.data(), _id: doc.id })),
      'dineInCourses'
    );

    this.props.dispatch({ type: 'INITIALIZE', dineInCourses });
  };

  componentDidUpdate(prevProps, prevState) {
    const prevStoreConfigKeysLength = Object.keys(prevProps?.storeConfig);
    const storeConfigKeysLength = Object.keys(this.props.storeConfig);

    if (prevStoreConfigKeysLength.length < storeConfigKeysLength.length) {
      this.storeConfigInLSAndTriggerInit();
      this.getUpdatedStoreConfig();
    }

    if (!prevState.homeClass && window.location.pathname === '/') {
      this.setState({
        homeClass: true,
      });
    } else if (prevState.homeClass && window.location.pathname !== '/') {
      this.setState({
        homeClass: false,
      });
    }
  }

  render() {
    let pageCls = clsx({
      'app-wrapper': !this.props.acceptedCall?.id,
      'app-wrapper-notification': !!this.props.acceptedCall?.id,
      'home-page': this.state.homeClass,
      'is-mobile': isMobile,
      'is-Safari': isSafari,
      'hide-status-bar': !this.props.acceptedCall?.id,
    });

    return (
      <>
        <ModalOverlay />
        <IntercomProvider>
          <VirtualKeyboardProvider>
            <NotificationsProvider storeId={this.props.storeConfig?.storeId}>
              <div
                className={pageCls}
                style={{
                  height:
                    this.props.screenSizes.height !== undefined &&
                    this.props.screenSizes.height
                      ? this.props.screenSizes.height
                      : '',
                }}
              >
                {isMacOSOrWindowsDevice() && !this.props.acceptedCall?.id && (
                  <StatusBar />
                )}
                {this.props.acceptedCall?.id && (
                  <CallNotificationStatusBar
                    id={this.props.acceptedCall?.id}
                    lineName={this.props.acceptedCall?.lineName}
                    phone={
                      this.props.acceptedCall?.customerName ||
                      this.props.acceptedCall?.phone
                    }
                    status={this.props.acceptedCall?.status}
                    handleOnClick={async () => {
                      const res = await updateCallInfo(
                        this.props.acceptedCall,
                        2
                      );
                      if (res?.success) {
                        this.props.setAcceptedCall({});
                        this.props.resetCurrentOrder();
                      }
                    }}
                  />
                )}
                <AppRoutes />
              </div>
              <VirtualKeyboard />
            </NotificationsProvider>
          </VirtualKeyboardProvider>
        </IntercomProvider>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    screenSizes: state.dashboardReducer.screenSizes,
    storeConfig: state.dashboardReducer.storeConfig,
    acceptedCall: state.dashboardReducer.acceptedCall,
    currentOrder: state.dashboardReducer.currentOrder,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    loadPublicHolidays: (
      storeId,
      restaurantId,
      timeZone,
      stateIdentifier,
      countryIdentifier
    ) =>
      dispatch(
        loadPublicHolidays(
          storeId,
          restaurantId,
          timeZone,
          stateIdentifier,
          countryIdentifier
        )
      ),
    appLoaded: dispatch(appLoaded()),
    setScreenSizes: (sizes) => dispatch(setScreenSizes(sizes)),
    updateStoreConfig: (storeConfig, allStores) =>
      dispatch(updateStoreConfig(storeConfig, allStores)),
    loadOrderSetup: (storeId) => dispatch(loadOrderSetup(storeId)),
    loadProductSetup: (storeId) => dispatch(loadProductSetup(storeId)),
    setOrderSetup: (orderSetup) => dispatch(setOrderSetup(orderSetup)),
    loadPosVersion: () => dispatch(loadPosVersion()),
    loadCloseDates: (storeId) => dispatch(loadCloseDates(storeId)),
    loadShifts: (storeId) => dispatch(loadShifts(storeId)),
    loadSuburbs: (restaurantId) => dispatch(loadSuburbs(restaurantId)),
    loadRefundReasons: () => dispatch(loadRefundReasons()),
    loadCreditCardsCharges: (storeId) =>
      dispatch(loadCreditCardsCharges(storeId)),
    loadCategories: (storeId) => dispatch(loadCategories(storeId)),
    loadMenuItems: (storeId, firebasePersistenceLogging) =>
      dispatch(loadMenuItems(storeId, firebasePersistenceLogging)),
    loadMenuItemSizes: (storeId) => dispatch(loadMenuItemSizes(storeId)),
    loadMenuItemModifiers: (storeId) =>
      dispatch(loadMenuItemModifiers(storeId)),
    loadMenuItemSubModifiers: (storeId) =>
      dispatch(loadMenuItemSubModifiers(storeId)),
    loadSpecials: (storeId) => dispatch(loadSpecials(storeId)),
    loadSoldOutItems: (storeId) => dispatch(loadSoldOutItems(storeId)),
    loadPinpads: (storeId) => dispatch(loadPinpads(storeId)),
    loadDrawers: (storeId) => dispatch(loadDrawers(storeId)),
    loadTerminalSettings: (storeId) => dispatch(loadTerminalSettings(storeId)),
    loadWindcavePinpads: (storeId) => dispatch(loadWindcavePinpads(storeId)),
    loadWindcaveUser: (storeId) => dispatch(loadWindcaveUser(storeId)),
    loadPaymentTypes: () => dispatch(loadPaymentTypes()),
    updateStripePayments: (stripeCardPayments) =>
      dispatch(updateStripePayments(stripeCardPayments)),
    fetchStoreWebsiteLoaderData: (storeId) =>
      dispatch(fetchStoreWebsiteLoaderData(storeId)),
    setAcceptedCall: (call) => dispatch(setAcceptedCall(call)),
    resetCurrentOrder: () => dispatch(resetCurrentOrder()),
    setMenuItemSizes: (menuItemSizes) =>
      dispatch(setMenuItemSizes(menuItemSizes)),
    setMenuItemSubModifiers: (menuItemSubModifiers) =>
      dispatch(setMenuItemSubModifiers(menuItemSubModifiers)),
  };
}

export const WithReduxDashboard = connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard);

export default React.memo(WithReduxDashboard);
