import { PosIconLoading } from 'assets/icons/PosIconLoading';
import classNames from 'classnames';
import SplitBillOption from 'components/SplitBillOption';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAmountWithCountryCurrency } from 'util/currencyFormat';
import { v4 } from 'uuid';
import { PosIconTrash } from '../assets/icons/PosIconTrash';
import scssVariables from '../assets/scss/global/_variables.module.scss';
import addDiscountToMenuItem from '../cartProcessor/addDiscountToMenuItem';
import addDiscountToOrder from '../cartProcessor/addDiscountToOrder';
import addVoucherToMenuItem from '../cartProcessor/addVoucherToMenuItem';
import addVoucherToOrder from '../cartProcessor/addVoucherToOrder';
import Button from '../components/Button';
import {
  getDiscountAction,
  getVoucherAction,
  resetActionsModalConfig,
  resetMenuItem,
  setCurrentOrderSelectedDiscount,
  updateCurrentOrder,
} from '../pages/Dashboard/action';
import actionTypes from '../pages/Dashboard/constants';
import {
  activeSpecialsSelector,
  currencyCodeSelector,
  currencySymbolSelector,
  currentMenuItemSelector,
  splitBillDiscountModeSelector,
  storeConfigSelector,
  storeCountryIdentifierSelector,
} from '../pages/Dashboard/selectors';
import { ModalsCarouselSlideUI, ScrollableBoxUI } from '../UI/components';
import { isObjectNotEmpty } from '../util/utils';
import { validateDiscount, validateVoucher } from '../util/voucherHelper';
import { ItemManualDiscountAmount } from './ItemManualAmount';
import { _auth } from '../firebase';
import { v4 as uuidv4 } from 'uuid';
import { getServiceID } from 'util/utils';

const SplitBillDiscountModalWrapper = (props) => {
  const open = useSelector(
    (state) => state.dashboardReducer.splitBillDiscountModal
  );

  if (!open) return null;

  return <SplitBillDiscountModal open={open} {...props} />;
};

const SplitBillDiscountModal = ({
  open,
  cb = () => {},
  order,
  isOrderScreen = false,
  selectedOrder,
  isPerSeatOrder = false,
}) => {
  const dispatch = useDispatch();
  const currencyCode = useSelector(currencyCodeSelector);
  const storeCountryIdentifier = useSelector(storeCountryIdentifierSelector);
  const inputRef = useRef(null);
  const timeout = useRef(0);
  const {
    discountList,
    currentOrder: dashboardOrder,
    voucherList,
    orderSetup,
    productSetup,
    suburbs,
    publicHolidays,
  } = useSelector((state) => state.dashboardReducer);

  const currentOrder = order || selectedOrder || dashboardOrder;

  const activeSpecials = useSelector(activeSpecialsSelector);
  const storeConfig = useSelector(storeConfigSelector);
  const currentMenuItem = useSelector(currentMenuItemSelector);
  const splitBillDiscountMode = useSelector(splitBillDiscountModeSelector);
  const currencySymbol = useSelector(currencySymbolSelector);
  const nextOrderConfigURL = useSelector(
    (state) => state.dashboardReducer.nextOrderConfigURL
  );
  const [showCustomDiscoundModal, setShowCustomDiscountModal] = useState(false);
  const [allDiscounts, setAllDiscounts] = useState(discountList);
  const [isApiLoading, setIsApiLoading] = useState(false);

  // modal called from the menu item
  const discountMenuItemMode = useMemo(
    () =>
      splitBillDiscountMode === 'fromMenuItem' &&
      isObjectNotEmpty(currentMenuItem),
    [currentMenuItem, splitBillDiscountMode]
  );

  // selected discount and voucher
  const currentMenuItemSelectedDiscount = useMemo(() => {
    if (currentMenuItem.isHalfHalf || currentMenuItem.isFourQuarters) {
      const currentHalfItem = currentMenuItem.items.find((item) => {
        return isObjectNotEmpty(item.selectedDiscount) && item.selectedDiscount;
      });
      return currentHalfItem && currentHalfItem.selectedDiscount;
    } else {
      return (
        isObjectNotEmpty(currentMenuItem) && currentMenuItem.selectedDiscount
      );
    }
  }, [currentMenuItem]);

  const currentOrderSelectedDiscount = useMemo(
    () =>
      isObjectNotEmpty(currentOrder.selectedDiscount) &&
      currentOrder.selectedDiscount,
    [currentOrder.selectedDiscount]
  );

  const currentMenuItemSelectedVoucher = useMemo(() => {
    if (currentMenuItem.isHalfHalf || currentMenuItem.isFourQuarters) {
      const item = currentMenuItem.items.length && currentMenuItem.items[0];
      return isObjectNotEmpty(item.selectedVoucher) && item.selectedVoucher;
    } else {
      return (
        isObjectNotEmpty(currentMenuItem.selectedVoucher) &&
        currentMenuItem.selectedVoucher
      );
    }
  }, [
    currentMenuItem.isFourQuarters,
    currentMenuItem.isHalfHalf,
    currentMenuItem.items,
    currentMenuItem.selectedVoucher,
  ]);

  const currentOrderSelectedVoucher = useMemo(
    () =>
      isObjectNotEmpty(currentOrder.selectedVoucher) &&
      currentOrder.selectedVoucher,
    [currentOrder.selectedVoucher]
  );

  // check if either order or item discount has been selected
  const [selectedDiscount, setSelectedDiscount] = useState(
    discountMenuItemMode
      ? currentMenuItemSelectedDiscount || {}
      : currentOrderSelectedDiscount || {}
  );

  const [voucherCode, setVoucherCode] = useState('');
  const [showVoucherModal, setShowVoucherModal] = useState(false);
  const [voucherError, setVoucherError] = useState('');
  const [discountError, setDiscountError] = useState('');

  // whether discount or voucher should be removed
  const [shouldRemoveItemDiscount, setShouldRemoveItemDiscount] =
    useState(false);
  const [shouldRemoveOrderDiscount, setShouldRemoveOrderDiscount] =
    useState(false);

  // discount list loading
  const [discountListLoading, setDiscountsIsLoading] = useState(true);

  useEffect(() => {
    const discounts = [...discountList, currentOrder.selectedDiscount];
    const filteredDiscounts = discounts.filter(
      (item, index) =>
        item?._id &&
        discounts.findIndex((dis) => dis?._id === item._id) === index
    );
    setAllDiscounts(filteredDiscounts);
  }, [discountList]);
  useEffect(() => {
    const actionObj = {
      storeId: storeConfig?.storeId,
      orderFrom: currentOrder.orderFrom,
      orderType: currentOrder.orderType,
      userId: currentOrder.userId ? currentOrder.userId : '',
    };

    // DiscountAction
    dispatch(getDiscountAction(actionObj));

    // VoucherAction
    dispatch(getVoucherAction(actionObj));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (showVoucherModal) {
      timeout.current = setTimeout(() => {
        inputRef.current.focus();
      }, 200);
    }

    return () => {
      clearTimeout(timeout.current);
    };
  }, [showVoucherModal]);

  useEffect(() => {
    if (isObjectNotEmpty(selectedDiscount)) {
      // manage remove menu item discount
      setShouldRemoveItemDiscount(
        Boolean(
          discountMenuItemMode &&
            currentMenuItemSelectedDiscount &&
            currentMenuItemSelectedDiscount._id === selectedDiscount._id
        )
      );

      // manage remove order discount
      setShouldRemoveOrderDiscount(
        Boolean(
          !discountMenuItemMode &&
            currentOrderSelectedDiscount &&
            currentOrderSelectedDiscount._id === selectedDiscount._id
        )
      );
    }
  }, [
    currentOrderSelectedDiscount,
    currentMenuItemSelectedDiscount,
    selectedDiscount,
    discountMenuItemMode,
  ]);

  useEffect(() => {
    if (discountList && discountList.length > 0) {
      setDiscountsIsLoading(false);
    } else {
      setDiscountsIsLoading(false);
    }
  }, [discountList]);

  // In edit mode, auto select a discount grid for the current order
  // if discountId is present in the currentOrder object
  // useEffect(() => {
  //   if (discountList?.length && currentOrder.discountId) {
  //     setSelectedDiscount(
  //       discountList.find((dl) => dl._id === currentOrder.discountId) ?? currentOrder.selectedDiscount ?? {}
  //     );
  //     dispatch(setCurrentOrderSelectedDiscount());
  //   }
  //   // eslint-disable-next-line
  // }, [dispatch, discountList, currentOrder.discountId]);

  const handleSubmit = (event) => {
    event.preventDefault();
    isOrderScreen || isPerSeatOrder
      ? handleOrderScreenVoucherApply()
      : onVoucherApply();
  };

  const handleOrderScreenVoucherApply = async (remove = false) => {
    let applyVoucher = false;
    let closeModal = false;
    let newSelectedVoucher = {};
    let orderParams = {
      currentOrder,
      currentMenuItem,
      activeSpecials,
      orderSetup,
      productSetup,
      suburbs,
      publicHolidays,
      storeConfig,
    };
    // remove the voucher based on the flag
    if (remove) {
      // pass empty voucher object to remove it
      orderParams = {
        ...orderParams,
        selectedVoucher: {},
      };
      applyVoucher = true;
      closeModal = true;
    } else {
      // run the apply logic
      if (voucherList && voucherList.length > 0) {
        let filteredVouchers = voucherList.filter((v) => {
          return (
            v.voucherCode.toLowerCase().trim() ===
            voucherCode.toLowerCase().trim()
          );
        });

        if (filteredVouchers.length > 0) {
          const validVoucher = validateVoucher(
            filteredVouchers[0],
            currentOrder,
            currentOrder.payableAmount,
            storeConfig
          );

          if (validVoucher.success) {
            newSelectedVoucher = { ...filteredVouchers[0] };

            const orderVoucherAlreadyApplied =
              isObjectNotEmpty(currentOrder.selectedVoucher) &&
              isObjectNotEmpty(newSelectedVoucher) &&
              currentOrder.selectedVoucher._id === newSelectedVoucher._id;

            const menuVoucherAlreadyApplied = currentOrder.menuItems.some(
              (item) =>
                isObjectNotEmpty(item.selectedVoucher) &&
                isObjectNotEmpty(newSelectedVoucher) &&
                item.selectedVoucher._id === newSelectedVoucher._id
            );

            if (!menuVoucherAlreadyApplied && !orderVoucherAlreadyApplied) {
              // add voucher object to the order params
              orderParams = {
                ...orderParams,
                selectedVoucher: newSelectedVoucher,
              };

              closeModal = true;
              applyVoucher = true;
            } else {
              setVoucherError('Voucher has been already applied');
            }
          } else {
            setVoucherError(validVoucher.voucherErrorMessage);
          }
        } else {
          setVoucherError('Voucher does not exist 2');
        }
      } else {
        setVoucherError('Vouchers are not available at the moment');
      }
    }

    if (applyVoucher) {
      // if the modal has been called from the single menu item
      // apply the voucher to that item otherwise apply it to the order

      const updatedOrder = addVoucherToOrder(orderParams);
      const requestObj = {
        order: {
          ...updatedOrder,
          isEditOrder: true,
          paymentType: '0',
          doNotUpdateOrderStatus: true,
        },
        payment: {
          type: '0',
          amount: 0,
        },
      };

      const response = await fetch(`${nextOrderConfigURL}/placeOrder3DSecure`, {
        method: 'post',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...requestObj,
        }),
      });

      const result = await response.json();

      // store the applied voucher
      dispatch({
        type: actionTypes.applyVoucher,
        appliedVoucher: newSelectedVoucher,
      });
    }
    // close and reset the modal
    if (closeModal) {
      setShowVoucherModal(false);
      dispatch({ type: actionTypes.toggleSplitDiscountModal });
      dispatch(resetActionsModalConfig());
    }
  };

  const onVoucherApply = (remove = false) => {
    let applyVoucher = false;
    let closeModal = false;
    let newSelectedVoucher = {};
    let orderParams = {
      currentOrder,
      currentMenuItem,
      activeSpecials,
      orderSetup,
      productSetup,
      suburbs,
      publicHolidays,
      storeConfig,
    };

    // remove the voucher based on the flag
    if (remove) {
      // pass empty voucher object to remove it
      orderParams = {
        ...orderParams,
        selectedVoucher: {},
      };
      applyVoucher = true;
      closeModal = true;
    } else {
      // run the apply logic
      if (voucherList && voucherList.length > 0) {
        let filteredVouchers = voucherList.filter((v) => {
          return (
            v.voucherCode.toLowerCase().trim() ===
            voucherCode.toLowerCase().trim()
          );
        });

        if (filteredVouchers.length > 0) {
          const validVoucher = validateVoucher(
            filteredVouchers[0],
            currentOrder,
            currentOrder.payableAmount,
            storeConfig
          );

          if (validVoucher.success) {
            newSelectedVoucher = { ...filteredVouchers[0] };

            const orderVoucherAlreadyApplied =
              isObjectNotEmpty(currentOrder.selectedVoucher) &&
              isObjectNotEmpty(newSelectedVoucher) &&
              currentOrder.selectedVoucher._id === newSelectedVoucher._id;

            const menuVoucherAlreadyApplied = currentOrder.menuItems.some(
              (item) =>
                isObjectNotEmpty(item.selectedVoucher) &&
                isObjectNotEmpty(newSelectedVoucher) &&
                item.selectedVoucher._id === newSelectedVoucher._id
            );

            if (!menuVoucherAlreadyApplied && !orderVoucherAlreadyApplied) {
              // add voucher object to the order params
              orderParams = {
                ...orderParams,
                selectedVoucher: newSelectedVoucher,
              };

              closeModal = true;
              applyVoucher = true;
            } else {
              setVoucherError('Voucher has been already applied');
            }
          } else {
            setVoucherError(validVoucher.voucherErrorMessage);
          }
        } else {
          setVoucherError('Voucher does not exist 2');
        }
      } else {
        setVoucherError('Vouchers are not available at the moment');
      }
    }

    // if no errors run dispatch and apply the voucher object
    if (applyVoucher) {
      // if the modal has been called from the single menu item
      // apply the voucher to that item otherwise apply it to the order
      if (discountMenuItemMode) {
        dispatch(updateCurrentOrder(addVoucherToMenuItem(orderParams)));
      } else {
        dispatch(updateCurrentOrder(addVoucherToOrder(orderParams)));
      }

      // store the applied voucher
      dispatch({
        type: actionTypes.applyVoucher,
        appliedVoucher: newSelectedVoucher,
      });
    }

    // close and reset the modal
    if (closeModal) {
      setShowVoucherModal(false);
      dispatch({ type: actionTypes.toggleSplitDiscountModal });
      dispatch(resetActionsModalConfig());
    }
  };

  const handleChange = (event) => {
    event.preventDefault();
    setVoucherCode(event.target.value);
    setVoucherError('');
  };

  const handleCloseModal = () => {
    dispatch({
      type: actionTypes.toggleSplitDiscountModal,
    });
    if (window.location.href.indexOf('split-bill') !== -1) {
      dispatch({
        type: 'deselectSplitSeat',
      });
    }
  };

  const handleOrderScreenApplyDiscount = async (e) => {
    setIsApiLoading(true);
    e?.preventDefault();

    if (!shouldRemoveItemDiscount || !shouldRemoveOrderDiscount) {
      const validDiscount = validateDiscount(
        selectedDiscount,
        currentOrder,
        currentOrder.payableAmount,
        storeConfig
      );

      if (!validDiscount.success) {
        setDiscountError(validDiscount.discountErrorMessage);
        setTimeout(() => {
          setDiscountError('');
        }, 5000);

        return;
      }
    }
    // either remove or add discount
    let newSelectedDiscount = { ...selectedDiscount };
    let updatedCurrentOrder = { ...currentOrder };

    // remove discount based on the flag
    if (
      (discountMenuItemMode && shouldRemoveItemDiscount) ||
      (!discountMenuItemMode && shouldRemoveOrderDiscount)
    ) {
      newSelectedDiscount = {};

      updatedCurrentOrder = {
        ...updatedCurrentOrder,
        menuItems:
          updatedCurrentOrder?.menuItems?.map((menuItem) =>
            menuItem?.totalDiscountAmt
              ? { ...menuItem, totalDiscountAmt: 0 }
              : { ...menuItem }
          ) || [],
      };
    }

    let orderParams = {
      currentOrder: updatedCurrentOrder,
      currentMenuItem,
      selectedDiscount: newSelectedDiscount,
      activeSpecials,
      orderSetup,
      productSetup,
      allSuburbs: suburbs,
      publicHolidays,
      storeConfig,
    };

    const updatedOrder = addDiscountToOrder(orderParams);

    let payments = {
      type: '0',
      amount: 0,
    };

    let paymentType = '0';

    if (
      selectedDiscount?.discountType === '1' &&
      selectedDiscount?.discount === '100'
    ) {
      paymentType = '1';
      payments = {
        storePinPadId: '000-000-000',
        merchantCode: 400,
        type: '1',
        amount: 0,
        isRefund: false,
        sessionId: uuidv4(),
        serviceId: getServiceID(uuidv4()),
      };
    }

    const requestObj = {
      order: {
        ...updatedOrder,
        isEditOrder: true,
        paymentType: paymentType,
        doNotUpdateOrderStatus: true,
      },
      payment: payments,
    };
    const response = await fetch(`${nextOrderConfigURL}/placeOrder3DSecure`, {
      method: 'post',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...requestObj,
      }),
    });

    const result = await response.json();

    dispatch({ type: actionTypes.toggleSplitDiscountModal });
    dispatch(resetActionsModalConfig());
    setIsApiLoading(false);
  };

  const handleApplyDiscount = (e) => {
    e?.preventDefault();
    // skip validation
    if (!shouldRemoveItemDiscount || !shouldRemoveOrderDiscount) {
      const validDiscount = validateDiscount(
        selectedDiscount,
        currentOrder,
        currentOrder.payableAmount,
        storeConfig
      );

      if (!validDiscount.success) {
        setDiscountError(validDiscount.discountErrorMessage);
        setTimeout(() => {
          setDiscountError('');
        }, 5000);

        return;
      }
    }

    // either remove or add discount
    let newSelectedDiscount = { ...selectedDiscount };
    let updatedOrder = { ...currentOrder };

    // remove discount based on the flag
    if (
      (discountMenuItemMode && shouldRemoveItemDiscount) ||
      (!discountMenuItemMode && shouldRemoveOrderDiscount)
    ) {
      newSelectedDiscount = {};

      updatedOrder = {
        ...updatedOrder,
        menuItems:
          updatedOrder?.menuItems?.map((menuItem) =>
            menuItem?.totalDiscountAmt
              ? { ...menuItem, totalDiscountAmt: 0 }
              : { ...menuItem }
          ) || [],
      };
    }

    // add discount to the order params
    let orderParams = {
      currentOrder: updatedOrder,
      currentMenuItem,
      selectedDiscount: newSelectedDiscount,
      activeSpecials,
      orderSetup,
      productSetup,
      allSuburbs: suburbs,
      publicHolidays,
      storeConfig,
    };

    // if the modal has been called from the single menu item
    // apply the discount to that item
    if (discountMenuItemMode) {
      dispatch(updateCurrentOrder(addDiscountToMenuItem(orderParams)));
      // TODO: move discount object to better place to be able to reset menut item
      dispatch(resetMenuItem());

      // otherwise apply the discount for the whole order
    } else {
      dispatch(updateCurrentOrder(addDiscountToOrder(orderParams)));
    }

    cb(addDiscountToOrder(orderParams));
    dispatch({ type: actionTypes.toggleSplitDiscountModal });
    dispatch(resetActionsModalConfig());
  };

  const handleDiscountSelection = (discount) => {
    if (!disabledDiscount(discount._id)) {
      setSelectedDiscount(discount);

      if (discountError) {
        setDiscountError('');
      }
    }
  };

  const handleRemoveVoucher = () => {
    setVoucherCode('');
    isOrderScreen || isPerSeatOrder
      ? handleOrderScreenVoucherApply(true)
      : onVoucherApply(true);
  };

  const renderselectedVoucherInfo = (voucher) => (
    <div className="voucher-modal-box__selected-voucher">
      {voucher.voucherCode} (${voucher.discount})
      <PosIconTrash
        onClick={handleRemoveVoucher}
        mainColor={scssVariables.posDangerColor}
        darkModeColor={scssVariables.posDangerColor}
        className="voucher-modal-box__remove"
      />
    </div>
  );

  const renderVoucherModal = () => {
    return (
      <div className="modal-wrap voucher-modal">
        <div
          className="modal-overlay"
          style={{ zIndex: 21 }}
          onClick={() => setShowVoucherModal(false)}
        />
        <div
          className="modal-box"
          style={{ width: 500, height: 342, zIndex: 22, bottom: 25 }}
        >
          <button
            type="button"
            className="back-modal-btn hover-highlight"
            style={{ top: 36 }}
            title="Back"
            onClick={() => setShowVoucherModal(false)}
          />

          <div className="customer-name-modal-box voucher-modal-box">
            <div className="customer-name-modal-inner">
              <h2 className="customer-name-title">Enter Voucher</h2>
              <div className="voucher-modal-box__selected-wrapper">
                {discountMenuItemMode &&
                  currentMenuItemSelectedVoucher &&
                  renderselectedVoucherInfo(currentMenuItemSelectedVoucher)}
                {!discountMenuItemMode &&
                  currentOrderSelectedVoucher &&
                  renderselectedVoucherInfo(currentOrderSelectedVoucher)}
              </div>
              <form
                action=""
                className="customer-name-form"
                onSubmit={handleSubmit}
                autoComplete="off"
              >
                <div className="single-input-box">
                  <label htmlFor="customer-name">Voucher Code</label>
                  <input
                    id="customer-name"
                    name="customer-name"
                    className="input-style white big"
                    value={voucherCode}
                    onChange={handleChange}
                    ref={inputRef}
                  />
                  {voucherError && (
                    <div style={{ color: '#944810', marginTop: 6 }}>
                      {voucherError}
                    </div>
                  )}
                </div>
              </form>
            </div>
            <div className="customer-name-btn-box">
              <Button
                className="btn"
                title="Apply Voucher"
                disabled={voucherCode.length < 2}
                onClick={() =>
                  isOrderScreen || isPerSeatOrder
                    ? handleOrderScreenVoucherApply()
                    : onVoucherApply()
                }
              >
                Apply Voucher
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderModalTitle = () => {
    let title = 'Select Discount Type';

    if (discountMenuItemMode) {
      title = (
        <>
          {title} <br />
          <span className="modal-sub-title">for {currentMenuItem.name}</span>
        </>
      );
    }

    return title;
  };

  const disabledDiscount = (discountId) => {
    return (
      // check if discount is already applied for the order
      (discountMenuItemMode &&
        isObjectNotEmpty(currentOrderSelectedDiscount) &&
        discountId === currentOrderSelectedDiscount._id) ||
      // check if discount is already applied for some menu item
      (!discountMenuItemMode &&
        currentOrder.menuItems.some(
          (item) =>
            isObjectNotEmpty(item.selectedDiscount) &&
            item.selectedDiscount._id === discountId
        ))
    );
  };

  const applyCustomDiscount = (discount) => {
    setShowCustomDiscountModal(false);

    const discountObj = {
      ...discountList[0],
      discount: discount.amount,
      isActive: true,
      _id: v4(),
      discountName: discount.discountName,
      discountType: discount.symbol === '%' ? '1' : '2',
    };
    setSelectedDiscount(discountObj);
    setAllDiscounts((prevState) => (prevState ?? []).concat(discountObj));
  };

  const discountMenuItemButtonLabel = shouldRemoveItemDiscount
    ? 'Remove Item'
    : 'Apply Item';
  const discountOrderButtonLabel = shouldRemoveOrderDiscount
    ? 'Remove Order'
    : 'Apply Order';
  const discountButtonLabel = discountMenuItemMode
    ? discountMenuItemButtonLabel
    : discountOrderButtonLabel;

  const modalCls = classNames({
    'booking-modal': true,
    show: true,
  });

  const applyBtnCls = classNames('btn big hover-highlight', {
    danger:
      (discountMenuItemMode && shouldRemoveItemDiscount) ||
      (!discountMenuItemMode && shouldRemoveOrderDiscount),
  });

  return (
    <>
      <div id="split-bill-discount-modal" className={modalCls}>
        {!showCustomDiscoundModal ? (
          <>
            <div className="modal-wrap">
              <form className="modal-box" autoComplete="off">
                <div
                  className="modal-header"
                  style={{
                    display: 'flex',
                    gap: 10,
                    alignItems: 'flex-start',
                  }}
                >
                  <h3
                    style={{
                      flex: 1,
                    }}
                  >
                    {renderModalTitle()}
                  </h3>

                  <button
                    type="button"
                    className="btn inverse round grey voucher-btn hover-highlight"
                    title="Voucher"
                    onClick={() => setShowCustomDiscountModal(true)}
                  >
                    <span className="inner-btn-box">Manual Discount</span>
                  </button>
                  <button
                    type="button"
                    className="btn inverse round grey voucher-btn hover-highlight"
                    title="Voucher"
                    onClick={() => {
                      setVoucherError('');
                      setShowVoucherModal(true);
                    }}
                  >
                    <span className="inner-btn-box">Voucher</span>
                  </button>
                </div>
                <div style={{ color: '#944810', margin: '0px 30px' }}>
                  {discountError ? discountError : ''}
                </div>
                <div className="modal-body">
                  {discountListLoading ? (
                    <div className="modal-loading-wrapper">
                      <PosIconLoading mainColor="#5eb602" />
                    </div>
                  ) : discountList?.length > 0 ? (
                    <ScrollableBoxUI
                    // actionType="modalBodyScroll"
                    >
                      <div className="modal-body-inner">
                        <div className="item-cards">
                          {allDiscounts
                            ?.filter((discount) => discount.isActive)
                            .map((discount) => {
                              let cardCls =
                                discount.slug === 'custom' ? 'custom' : '';

                              return (
                                <SplitBillOption
                                  key={discount._id}
                                  id={'_id'}
                                  subClass={cardCls}
                                  onClick={() =>
                                    handleDiscountSelection(discount)
                                  }
                                  item={discount}
                                  isChecked={(discount) => {
                                    return (
                                      discount._id === selectedDiscount?._id
                                    );
                                  }}
                                  getTitle={(discount) => discount.discountName}
                                  getDescription={(discount) =>
                                    discount.discountType === '1'
                                      ? `${discount.discount}%`
                                      : getAmountWithCountryCurrency({
                                          amount: Number(discount.discount),
                                          locale: `en-${storeCountryIdentifier}`,
                                          currencyCode,
                                        })
                                  }
                                  disabled={disabledDiscount(discount._id)}
                                />
                              );
                            })}
                        </div>
                      </div>
                    </ScrollableBoxUI>
                  ) : (
                    <div className="modal-body-inner">
                      No discounts available
                    </div>
                  )}
                </div>

                <div className="modal-footer modal-btn-box">
                  <Button
                    className={applyBtnCls}
                    title={`${discountButtonLabel} Discount`}
                    onClick={
                      isOrderScreen
                        ? handleOrderScreenApplyDiscount
                        : handleApplyDiscount
                    }
                    disabled={!selectedDiscount._id || isApiLoading}
                    enableThemeColor
                  >
                    {`${discountButtonLabel} Discount`}
                  </Button>
                </div>
              </form>
            </div>

            {showVoucherModal && renderVoucherModal()}
          </>
        ) : (
          <div
            style={{
              height: '100%',
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'center',
            }}
          >
            <ModalsCarouselSlideUI
              style={{
                top: 'unset',
              }}
            >
              <ItemManualDiscountAmount
                id="manual-discount"
                originalPrice={100}
                onBack={() => setShowCustomDiscountModal(false)}
                onApply={applyCustomDiscount}
                currencySymbol={currencySymbol}
                sendDetails
              />
            </ModalsCarouselSlideUI>
          </div>
        )}

        <div className="modal-overlay" onClick={handleCloseModal} />
      </div>
    </>
  );
};

export default SplitBillDiscountModalWrapper;
