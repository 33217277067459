import axios from 'axios';
import { MENU_ORDER_FROM, OrderTypeIdEnum } from 'configuration/enums';
import { nextOrderConfig } from 'configuration/nextOrderConfig';
import { getSingleSizedItemWithMeta } from '../../components/HalfHalfMenuItems/HalfHalfMenuItemsWrapper';
import { _firestore } from '../../firebase';
import {
  getItemBaseSubModifierId,
  updateModifier,
  updateSize,
} from '../../itemProcessor';
import {
  getCurrentMomentDate,
  getTimestampStartOfTheDay,
} from '../../util/timeHelper';
import { getDiscounts, getVouchers } from '../../util/voucherHelper';
import { default as ActionTypes, default as actionTypes } from './constants';
import { _generateCurrentHalfHalfData } from './reducer';
import {
  isAuthDisabledForPOSSelector,
  printCustomerReceiptAutomaticallyOnPOSSelector,
  storeIdSelector,
} from './selectors';

export function updateStripePayments(stripeCardPayments) {
  return {
    type: ActionTypes.SET_STRIPE_CARD_PAYMENTS,
    payload: {
      stripeCardPayments,
    },
  };
}

export function updateStoreConfig(storeConfig, allStores) {
  return {
    type: ActionTypes.UPDATE_STORE_CONFIG,
    payload: {
      storeConfig,
      allStores,
    },
  };
}

let unsubscribePublicHolidays = '';
export function loadPublicHolidays(
  timeZone,
  stateIdentifier,
  countryIdentifier,
  storeId
) {
  if (unsubscribePublicHolidays) unsubscribePublicHolidays();
  return async function (dispatch) {
    unsubscribePublicHolidays = _firestore
      .collection('PublicHolidays')
      .where('countryIdentifier', '==', countryIdentifier.toUpperCase())
      .where('stateList', 'array-contains-any', [
        stateIdentifier.toUpperCase(),
        storeId,
        'ALL',
      ])
      .onSnapshot(async (querySnapshot) => {
        let dataArray = [];
        querySnapshot.docs.forEach((doc) => {
          dataArray.push({ _id: doc.id, ...doc.data() });
        });

        let days = [];
        let today = getCurrentMomentDate(timeZone);
        days.push(today.format('YYYY-MM-DD'));
        for (var i = 1; i < 10; i++) {
          days.push(today.add(1, 'days').format('YYYY-MM-DD'));
        }

        const filteredPublicHolidays = days.map((day) => {
          if (dataArray.some((publicHoliday) => publicHoliday.date === day)) {
            return day;
          }
          return null;
        });

        const publicHolidays = await Promise.all(
          filteredPublicHolidays.map((filteredDay) => {
            return _firestore
              .collection('PublicHolidayOptouts')
              .where('date', '==', filteredDay)
              .where('storeId', '==', storeId)
              .get()
              .then((querySnapshot1) => {
                if (querySnapshot1.docs.length <= 0) {
                  return filteredDay;
                }

                return null;
              });
          })
        );

        dispatch({
          type: ActionTypes.LOAD_PUBLIC_HOLIDAYS,
          publicHolidays,
        });
      });
  };
}

export function loadCreditCardsCharges(storeId) {
  return (dispatch) => {
    _firestore
      .collection('CreditCardProcessingFees')
      .where('storeId', '==', storeId)
      .where('merchantCode', '==', 300)
      .get()
      .then((querySnapshot) => {
        const creditCardsCharges = querySnapshot.docs
          ? querySnapshot.docs.map((doc) => ({ _id: doc.id, ...doc.data() }))
          : [];
        dispatch({
          type: ActionTypes.LOAD_CREDIT_CARDS_CHARGES,
          creditCardsCharges: creditCardsCharges,
        });
      });
  };
}

let unsubscribeCategories = '';
export function loadCategories(storeId) {
  if (unsubscribeCategories) unsubscribeCategories();
  return (dispatch) => {
    unsubscribeCategories = _firestore
      .collection('Categories')
      .where('stores', 'array-contains', storeId)
      .where('isDeleted', '==', false)
      .orderBy('order')
      .onSnapshot((querySnapshot) => {
        let dataArray = [];
        querySnapshot.docs.forEach((doc) => {
          dataArray.push({ _id: doc.id, ...doc.data() });
        });
        dispatch({
          type: ActionTypes.LOAD_CATEGORIES,
          categories: dataArray,
        });
      });
  };
}

let unsubscribeMenuItems = '';
export function loadMenuItems(storeId, firebasePersistenceLogging) {
  if (unsubscribeMenuItems) unsubscribeMenuItems();
  return (dispatch) => {
    unsubscribeMenuItems = _firestore
      .collection('MenuItems')
      .where('stores', 'array-contains', storeId)
      .where('isDeleted', '==', false)
      .onSnapshot((querySnapshot) => {
        const dataSource = querySnapshot.metadata.fromCache
          ? 'local cache'
          : 'server';
        !!firebasePersistenceLogging &&
          console.log('Menu Items Data came from ' + dataSource);
        const data = querySnapshot.docs.map((doc) => {
          let {
            categoryId,
            courseId,
            courseName,
            name,
            isNameHidden,
            keywords,
            urlS3,
            date,
            dateTimestamp,
            isAvailable,
            storeId,
            stores,
            restaurantId,
            selectedExtraIngredients,
            selectedIngredients,
            selectedSizes,
            selectedModifiers,
            description,
            displayOrder,
            itemType,
            totalRevenue,
            baseModifierId,
            orderFromKeys,
            orderTypeKeys,
            isPublished,
          } = doc.data();
          return {
            _id: doc.id,
            courseId,
            courseName,
            categoryId,
            name,
            isNameHidden,
            keywords,
            urlS3,
            date,
            dateTimestamp,
            isAvailable,
            storeId: storeId ? storeId : '',
            stores: stores ? stores : [],
            restaurantId,
            selectedExtraIngredients,
            selectedIngredients,
            selectedSizes,
            selectedModifiers,
            description,
            displayOrder: displayOrder ? displayOrder : 0,
            itemType,
            totalRevenue: totalRevenue ? totalRevenue : 0,
            baseModifierId: baseModifierId ? baseModifierId : '',
            orderFromKeys: orderFromKeys
              ? orderFromKeys
              : [MENU_ORDER_FROM.web, MENU_ORDER_FROM.pos],
            orderTypeKeys: orderTypeKeys
              ? orderTypeKeys
              : [
                  OrderTypeIdEnum.WALK_IN,
                  OrderTypeIdEnum.DINE_IN,
                  OrderTypeIdEnum.PICK_UP,
                  OrderTypeIdEnum.DELIVERY,
                ],
            isPublished,
          };
        });
        dispatch({
          type: ActionTypes.LOAD_MENUITEMS,
          menuItems: data,
        });
      });
  };
}

let unsubscribeMenuItemSizes = '';
export function loadMenuItemSizes(storeId) {
  if (unsubscribeMenuItemSizes) unsubscribeMenuItemSizes();
  return (dispatch) => {
    unsubscribeMenuItemSizes = _firestore
      .collection('MenuItemSizes')
      .where('storeId', '==', storeId)
      .where('orderFrom', '==', MENU_ORDER_FROM.pos)
      .onSnapshot((querySnapshot) => {
        let dataArray = [];
        querySnapshot.docs.forEach((doc) => {
          dataArray.push({ _id: doc.id, ...doc.data() });
        });
        dispatch({
          type: ActionTypes.LOAD_MENUITEM_SIZES,
          menuItemSizes: dataArray,
        });
      });
  };
}

export const setMenuItemSizes = (data) => {
  return {
    type: ActionTypes.LOAD_MENUITEM_SIZES,
    menuItemSizes: data,
  };
};

export const setMenuItemSubModifiers = (data) => {
  return {
    type: ActionTypes.LOAD_MENUITEM_SUBMODIFIERS,
    menuItemSubModifiers: data,
  };
};

let unsubscribeMenuItemModifiers = '';
export function loadMenuItemModifiers(storeId) {
  if (unsubscribeMenuItemModifiers) unsubscribeMenuItemModifiers();
  return (dispatch) => {
    unsubscribeMenuItemModifiers = _firestore
      .collection('MenuItemModifiers')
      .where('storeId', '==', storeId)
      .where('orderFrom', '==', MENU_ORDER_FROM.pos)
      .onSnapshot((querySnapshot) => {
        let dataArray = [];
        querySnapshot.docs.forEach((doc) => {
          dataArray.push({ _id: doc.id, ...doc.data() });
        });
        dispatch({
          type: ActionTypes.LOAD_MENUITEM_MODIFIERS,
          menuItemModifiers: dataArray,
        });
      });
  };
}

let unsubscribeMenuItemSubModifiers = '';
export function loadMenuItemSubModifiers(storeId) {
  if (unsubscribeMenuItemSubModifiers) unsubscribeMenuItemSubModifiers();
  return (dispatch) => {
    unsubscribeMenuItemSubModifiers = _firestore
      .collection('MenuItemSubModifiers')
      .where('storeId', '==', storeId)
      .where('orderFrom', '==', MENU_ORDER_FROM.pos)
      .onSnapshot((querySnapshot) => {
        let dataArray = [];
        querySnapshot.docs.forEach((doc) => {
          dataArray.push({ _id: doc.id, ...doc.data() });
        });
        dispatch({
          type: ActionTypes.LOAD_MENUITEM_SUBMODIFIERS,
          menuItemSubModifiers: dataArray,
        });
      });
  };
}

let unsubscribeSpecials = '';
export function loadSpecials(storeId) {
  if (unsubscribeSpecials) unsubscribeSpecials();
  return (dispatch) => {
    unsubscribeSpecials = _firestore
      .collection('Specials')
      .where('stores', 'array-contains', storeId)
      .where('isDeleted', '==', false)
      .onSnapshot((querySnapshot) => {
        let dataArray = [];
        querySnapshot.docs.forEach((doc) => {
          dataArray.push({ _id: doc.id, ...doc.data() });
        });
        dispatch({
          type: ActionTypes.LOAD_SPECIALS,
          specials: dataArray,
        });
      });
  };
}

let unsubscribeSoldOutItems = '';
export function loadSoldOutItems(storeId) {
  if (unsubscribeSoldOutItems) unsubscribeSoldOutItems();
  return (dispatch) => {
    unsubscribeSoldOutItems = _firestore
      .collection('SoldOutMenuItems')
      .where('storeId', '==', storeId)
      .onSnapshot((querySnapshot) => {
        let dataArray = [];
        querySnapshot.docs.forEach((doc) => {
          dataArray.push({ _id: doc.id, ...doc.data() });
        });

        dispatch({
          type: ActionTypes.LOAD_SOLDOUTS_ITEM,
          soldOutItems: dataArray,
        });
      });
  };
}

export function loadWindcaveUser(initialStoreId) {
  return async (dispatch, getState) => {
    const storeId = initialStoreId || storeIdSelector(getState());
    const windCaveResponse = await axios.get(
      `${nextOrderConfig.baseUrl}/windcave/v1/user?storeId=${storeId}`,
      {
        storeId,
      }
    );

    if (windCaveResponse?.status === 201) {
      dispatch({
        type: ActionTypes.GET_WINDCAVE_USER_LIST,
        windcaveUserList: windCaveResponse?.data?.data,
      });
    }
  };
}

export function loadWindcavePinpads(initialStoreId) {
  return async (dispatch, getState) => {
    const storeId = initialStoreId || storeIdSelector(getState());
    const windCaveResponse = await axios.get(
      `${nextOrderConfig.baseUrl}/windcave/v1/terminal?storeId=${storeId}`,
      {
        storeId,
      }
    );

    if (windCaveResponse?.status === 201) {
      let windcaveTerminals = [];
      windCaveResponse?.data?.data.forEach((terminal) => {
        windcaveTerminals.push({
          ...terminal,
          _id: terminal.terminalId,
          terminalName: terminal.terminalId,
          merchantCode: 600,
        });
      });

      dispatch({
        type: ActionTypes.GET_PINPADS,
        pinPads: windcaveTerminals,
      });
    }
  };
}

export function loadPinpads(initialStoreId) {
  return (dispatch, getState) => {
    const storeId = initialStoreId || storeIdSelector(getState());

    axios
      .post(`${nextOrderConfig.cloudFunctionUrl}/getStoreTerminals`, {
        storeId,
      })
      .then(async (response) => {
        const terminals = response?.data?.terminals?.map((terminal) => {
          return { ...terminal, terminalName: terminal.displayName };
        });
        dispatch({
          type: ActionTypes.GET_PINPADS,
          pinPads: terminals,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

let unsubscribeDrawers = '';
export function loadDrawers(initialStoreId) {
  if (unsubscribeDrawers) unsubscribeDrawers();
  return (dispatch, getState) => {
    try {
      const storeId = initialStoreId || storeIdSelector(getState());

      unsubscribeDrawers = _firestore
        .collection('CashDrawers')
        .where('storeId', '==', storeId)
        .where('isDeleted', '==', false)
        .onSnapshot((querySnapshot) => {
          let dataArray = [];
          querySnapshot.docs.forEach((doc) => {
            dataArray.push({ _id: doc.id, ...doc.data() });
          });
          dispatch({
            type: ActionTypes.GET_DRAWERS,
            drawers: dataArray,
          });
        });
    } catch (e) {
      console.error('LOAD DRAWERS ERROR', e);
    }
  };
}

export function loadTables(storeId) {
  return (dispatch) => {
    _firestore
      .collection('Tables')
      .where('storeId', '==', storeId)
      .where('isDeleted', '==', false)
      .onSnapshot((querySnapshot) => {
        let dataArray = [];
        querySnapshot.docs.forEach((doc) => {
          dataArray.push({ _id: doc.id, ...doc.data() });
        });
        dispatch({
          type: ActionTypes.GET_TABLES,
          tables: dataArray,
        });
      });
  };
}

let unsubscribeOrderPayments = '';
export function loadOrderPayments(orderId) {
  if (unsubscribeOrderPayments) unsubscribeOrderPayments();
  return (dispatch) => {
    dispatch(setApiLoading(true));
    unsubscribeOrderPayments = _firestore
      .collection('OrderPayments')
      .where('orderId', '==', orderId)
      .onSnapshot((querySnapshot) => {
        let dataArray = [];
        querySnapshot.docs.forEach((doc) => {
          dataArray.push({ _id: doc.id, ...doc.data() });
        });
        dispatch(setOrderPayments(dataArray));
        dispatch(setApiLoading(false));
      });
  };
}

let unsubscribeTerminalSettings = '';
export function loadTerminalSettings(initialStoreId) {
  if (unsubscribeTerminalSettings) unsubscribeTerminalSettings();
  return (dispatch, getState) => {
    try {
      const storeId = initialStoreId || storeIdSelector(getState());

      unsubscribeTerminalSettings = _firestore
        .collection('TerminalSettings')
        .where('storeId', '==', storeId)
        .where('uuid', '==', localStorage.getItem('uuidForTerminalSettings'))
        .onSnapshot((querySnapshot) => {
          let dataArray = [];
          querySnapshot.docs.forEach((doc) => {
            dataArray.push({ _id: doc.id, ...doc.data() });
          });
          dispatch({
            type: ActionTypes.LOAD_TERMINAL_SETTING,
            terminalSettings: dataArray[0],
          });
        });
    } catch (e) {
      console.log('LOAD TERMINAL SETTINGS ERROR');
      console.error(e);
    }
  };
}

let unsubscribeCloseDates = '';
export function loadCloseDates(storeId) {
  if (unsubscribeCloseDates) unsubscribeCloseDates();
  return (dispatch) => {
    let timeStamp = getTimestampStartOfTheDay();
    unsubscribeCloseDates = _firestore
      .collection('CloseDates')
      .where('storeId', '==', storeId)
      .where('closeDateTimestamp', '>=', timeStamp)
      .onSnapshot((querySnapshot) => {
        let dataArray = [];
        querySnapshot.docs.forEach((doc) => {
          dataArray.push({ _id: doc.id, ...doc.data() });
        });
        dispatch({
          type: ActionTypes.LOAD_CLOSE_DATES,
          closeDates: dataArray,
        });
      });
  };
}

let unsubscribeOrderSetup = '';
export function loadOrderSetup(storeId) {
  if (unsubscribeOrderSetup) {
    unsubscribeOrderSetup();
  }
  return (dispatch, getState) => {
    const isAuthDisabledForPOS = isAuthDisabledForPOSSelector(getState());
    const printCustomerReceiptAutomaticallyOnPOS =
      printCustomerReceiptAutomaticallyOnPOSSelector(getState());

    unsubscribeOrderSetup = _firestore
      .collection('OrderSetup')
      .where('storeId', '==', storeId)
      .onSnapshot((querySnapshot) => {
        let dataArray = [];
        querySnapshot.docs.forEach((doc) => {
          dataArray.push({ _id: doc.id, ...doc.data() });
        });
        if (
          typeof dataArray[0]?.isAuthDisabledForPOS === 'boolean' &&
          isAuthDisabledForPOS !== dataArray[0]?.isAuthDisabledForPOS
        ) {
          localStorage.setItem(
            'isAuthCodeRequired',
            JSON.stringify({
              isAuthDisabledForPOS: dataArray[0]?.isAuthDisabledForPOS,
            })
          );
        }
        if (
          typeof dataArray[0]?.printCustomerReceiptAutomaticallyOnPOS ===
            'boolean' &&
          printCustomerReceiptAutomaticallyOnPOS !==
            dataArray[0]?.printCustomerReceiptAutomaticallyOnPOS
        ) {
          localStorage.setItem(
            'printCustomerReceiptAutomaticallyOnPOS',
            JSON.stringify({
              printCustomerReceiptAutomaticallyOnPOS:
                dataArray[0]?.printCustomerReceiptAutomaticallyOnPOS,
            })
          );
        }

        dispatch(setOrderSetup(dataArray.length > 0 ? dataArray[0] : {}));
      });
  };
}

let unsubscribeProductSetup = '';
export function loadProductSetup(storeId) {
  if (unsubscribeProductSetup) unsubscribeProductSetup();
  return (dispatch) => {
    unsubscribeProductSetup = _firestore
      .collection('ProductSetup')
      .where('storeId', '==', storeId)
      .onSnapshot((querySnapshot) => {
        var dataArray = [];
        querySnapshot.forEach((doc) => {
          dataArray.push({ _id: doc.id, ...doc.data() });
        });
        dispatch(setProductSetup(dataArray.length > 0 ? dataArray[0] : {}));
      });
  };
}

let unsubscribePOSversion = '';
export function loadPosVersion() {
  if (unsubscribePOSversion) unsubscribePOSversion();
  return (dispatch) => {
    unsubscribePOSversion = _firestore
      .collection('_NextOrder')
      .onSnapshot((querySnapshot) => {
        let dataArray = [];
        querySnapshot.docs.forEach((doc) => {
          dataArray.push({ _id: doc.id, ...doc.data() });
        });
        const { posVersion } = dataArray[0];
        const posVersionInLS = localStorage.getItem('posVersion');
        if (posVersion !== posVersionInLS) {
          dispatch(setRefreshToUpdatePosVersion(posVersion));
        }
      });
  };
}

let unsubscribeShifts = '';
export function loadShifts(storeId) {
  if (unsubscribeShifts) unsubscribeShifts();
  return (dispatch) => {
    unsubscribeShifts = _firestore
      .collection('Shifts')
      .where('storeId', '==', storeId)
      .onSnapshot((querySnapshot) => {
        let dataArray = [];
        querySnapshot.docs.forEach((doc) => {
          dataArray.push({ _id: doc.id, ...doc.data() });
        });
        dispatch({
          type: ActionTypes.LOAD_SHIFTS,
          shifts: dataArray,
        });
      });
  };
}

let unsubscribeSuburbs = '';
export function loadSuburbs(restaurantId) {
  if (unsubscribeSuburbs) unsubscribeSuburbs();
  return (dispatch) => {
    unsubscribeSuburbs = _firestore
      .collection('Suburbs')
      .where('restaurantId', '==', restaurantId)
      .onSnapshot((querySnapshot) => {
        let dataArray = [];
        querySnapshot.docs.forEach((doc) => {
          dataArray.push({ _id: doc.id, ...doc.data() });
        });
        dispatch({
          type: ActionTypes.LOAD_SUBURBS,
          suburbs: dataArray,
        });
      });
  };
}

let unsubscribeRefundReasons = '';
export function loadRefundReasons() {
  if (unsubscribeSuburbs) unsubscribeRefundReasons();
  return (dispatch) => {
    unsubscribeRefundReasons = _firestore
      .collection('RefundReasons')
      .onSnapshot((querySnapshot) => {
        let dataArray = [];
        querySnapshot.docs.forEach((doc) => {
          dataArray.push({ _id: doc.id, ...doc.data() });
        });
        dispatch({
          type: ActionTypes.LOAD_REFUND_REASONS,
          refundReasons: dataArray,
        });
      });
  };
}

export function setApiLoading(apiLoading) {
  return {
    type: actionTypes.API_LOADING,
    apiLoading,
  };
}

export function setIsCancelTransactionApiLoading(
  isCancelTransactionApiLoading
) {
  return {
    type: actionTypes.IS_CANCEL_TRANSACTION_API_LOADING,
    isCancelTransactionApiLoading,
  };
}

export function setOrderSetup(orderSetup) {
  return {
    type: ActionTypes.GET_ORDER_SETUP,
    orderSetup,
  };
}

export function setIsOrdersLoading(isOrdersLoading) {
  return {
    type: ActionTypes.SET_IS_ORDERS_LOADING,
    isOrdersLoading,
  };
}

export function setIsEditPhoneNumber(isEditPhoneNumber) {
  return {
    type: ActionTypes.SET_IS_EDIT_PHONE_NUMBER,
    isEditPhoneNumber,
  };
}

export function setProductSetup(productSetup) {
  return {
    type: ActionTypes.GET_PRODUCT_SETUP,
    productSetup,
  };
}

export function toggleSingleOption(optionName) {
  return {
    type: actionTypes.ToggleSingleOption,
    payload: optionName,
  };
}

export function toggleFullScreen() {
  return {
    type: actionTypes.ToggleFullScreen,
  };
}

export function toggleVirtualKeyboard() {
  return {
    type: actionTypes.ToggleVirtualKeyboard,
  };
}

export function toggleBlurEffect(blurEffect) {
  return {
    type: actionTypes.ToggleBlurEffect,
    blurEffect,
  };
}

export function appLoaded() {
  return {
    type: actionTypes.AppLoaded,
  };
}

export function setIsSingleHalfFlow(isSingleHalfFlow) {
  return {
    type: ActionTypes.SetSingleHalfFlow,
    isSingleHalfFlow,
  };
}

export function setShowPizzaActionButton(showPizzaActionButton) {
  return {
    type: ActionTypes.SetShowPizzaActionButton,
    showPizzaActionButton,
  };
}

export function openMenuItem(menuItem, isCurrentMenuItemInEditMode) {
  return {
    type: ActionTypes.OpenMenuItem,
    menuItem,
    isCurrentMenuItemInEditMode,
  };
}

export function toggleItemLargePhotoView() {
  return {
    type: actionTypes.ToggleItemLargePhotoView,
  };
}

export function updateMenuItem(menuItem) {
  return {
    type: ActionTypes.UpdateMenuItem,
    menuItem,
  };
}

function compareObjects(obj1, obj2) {
  // Create an object to store the differences
  var diff = {};

  // Loop through the first object's properties
  for (var prop in obj1) {
    if (obj1?.hasOwnProperty(prop)) {
      // If the property exists in the second object and the values are different, add it to the diff object
      if (obj2?.hasOwnProperty(prop)) {
        if (JSON.stringify(obj1[prop]) !== JSON.stringify(obj2[prop])) {
          diff[prop] = [obj1[prop], obj2[prop]];
        }
      } else {
        // If the property doesn't exist in the second object, add it to the diff object
        diff[prop] = [obj1[prop], undefined];
      }
    }
  }

  // Loop through the second object's properties
  for (var prop in obj2) {
    if (obj2?.hasOwnProperty(prop)) {
      // If the property doesn't exist in the first object, add it to the diff object
      if (!obj1?.hasOwnProperty(prop)) {
        diff[prop] = [undefined, obj2[prop]];
      }
    }
  }

  // Return the diff object
  return diff;
}

function checkOnlyDiscountAdded(diff, currentOrder) {
  let onlyDiscountAdded = true;
  let onlyVoucherDiscountAdded = true;
  let onlyDiscountPerItemAttributes = true;
  let newMenuItemAdded = false;

  const { menuItems, iteration } = currentOrder;

  const discountPerItemAttributes = ['payableAmount'];

  const discountAttributes = [
    'discount',
    'discountId',
    'discountName',
    'payableAmount',
    'selectedDiscount',
  ];

  const voucherDiscountAttributes = [
    'voucherDiscount',
    'voucherId',
    'voucherName',
    'payableAmount',
    'selectedVoucher',
  ];

  menuItems.forEach((item) => {
    if (item.iteration === iteration) {
      newMenuItemAdded = true;
    }
  });

  discountAttributes.forEach((attribute) => {
    if (!diff[attribute]) {
      onlyDiscountAdded = false;
    }
  });

  discountPerItemAttributes.forEach((attribute) => {
    if (!diff[attribute]) {
      onlyDiscountPerItemAttributes = false;
    }
  });

  voucherDiscountAttributes.forEach((attribute) => {
    if (!diff[attribute]) {
      onlyVoucherDiscountAdded = false;
    }
  });

  return (
    !newMenuItemAdded &&
    (onlyDiscountAdded ||
      onlyVoucherDiscountAdded ||
      onlyDiscountPerItemAttributes)
  );
}

export function updateCurrentOrder(currentOrder) {
  return (dispatch, getState) => {
    const { newOrdersReducer } = getState();

    let doNotUpdateOrderStatus = false;
    if (currentOrder?.iteration > 0) {
      const currentOrderIntialState =
        newOrdersReducer?.orders?.allOrdersMap[currentOrder._id];

      const difference = compareObjects(currentOrderIntialState, currentOrder);
      if (
        currentOrder?.discountId ||
        currentOrder?.voucherId ||
        currentOrder?.payableAmount
      ) {
        doNotUpdateOrderStatus = checkOnlyDiscountAdded(
          difference,
          currentOrder
        );
      }
    }
    dispatch({
      type: ActionTypes.UpdateCurrentOrder,
      currentOrder: { ...currentOrder, doNotUpdateOrderStatus },
    });
  };
  // return {
  //   type: ActionTypes.UpdateCurrentOrder,
  //   currentOrder,
  // };
}

export function setCurrentOrderSelectedDiscount() {
  return {
    type: ActionTypes.SET_CURRENT_ORDER_SELECTED_DISCOUNT,
  };
}

export function resetMenuItem() {
  return {
    type: ActionTypes.ResetMenuItem,
  };
}

export function resetCurrentOrder() {
  return {
    type: ActionTypes.ResetCurrentOrder,
  };
}

export function updateCurrentOrderDeliveryLocation(location) {
  return {
    type: ActionTypes.UpdateCurrentOrderDeliveryLocation,
    location,
  };
}

export function resetCurrentOrderDeliveryLocation() {
  return {
    type: ActionTypes.ResetCurrentOrderDeliveryLocation,
  };
}

export function setActionsModalConfig(config) {
  return {
    type: ActionTypes.SetActionsModalConfig,
    config,
  };
}

export function resetActionsModalConfig() {
  return {
    type: ActionTypes.ResetActionsModalConfig,
  };
}

export function setItemActionsModalConfig(config) {
  return {
    type: ActionTypes.SetItemActionsModalConfig,
    config,
  };
}

export function resetItemActionsModalConfig() {
  return {
    type: ActionTypes.ResetItemActionsModalConfig,
  };
}

export function updateCurrentSpecialsItem(specialsItem) {
  return {
    type: ActionTypes.UpdateCurrentSpecialsItem,
    specialsItem,
  };
}

export function resetCurrentSpecialsItem() {
  return {
    type: ActionTypes.ResetCurrentSpecialsItem,
  };
}

export function toggleSpecialsCategoryEditMode(categoryIndex) {
  return {
    type: ActionTypes.ToggleSpecialsCategoryEditMode,
    categoryIndex,
  };
}

export function updateCurrentSpecialsCategoryItem(categoryIndex, menuItem) {
  return {
    type: ActionTypes.UpdateCurrentSpecialsCategoryItem,
    categoryIndex,
    menuItem,
  };
}

export function setHalfHalfMenuItems(categoryId) {
  return {
    type: ActionTypes.SET_HALF_HALF_MENU_ITEMS,
    categoryId,
  };
}

export function updateCurrentHalfHalfData(categoryId) {
  return {
    type: ActionTypes.UpdateCurrentHalfHalfData,
    categoryId,
  };
}

export function setHalfHalfItemsForModify(
  menuItem,
  halfIndex,
  size,
  variantSelected
) {
  return {
    type: ActionTypes.SetHalfHalfForModify,
    menuItem,
    halfIndex,
    size,
    variantSelected,
  };
}

export function setHalfHalfSelectedVariant(toSetVariant) {
  return {
    type: ActionTypes.TOGGLE_HALF_HALF_SELECTED_VARIANT,
    payload: {
      toSetVariant,
    },
  };
}

export function setIncomingCallsNotifications(incomingCallsNotifications) {
  return (dispatch, getState) => {
    const { dashboardReducer: state } = getState();

    const prevNotifications = state.incomingCallsNotifications || [];
    let allNotifications = [];

    if (prevNotifications.length === 0) {
      allNotifications = incomingCallsNotifications;
    } else {
      const allNewNotificationIds = incomingCallsNotifications.map((i) => i.id);
      allNotifications = incomingCallsNotifications.concat(
        prevNotifications.filter((i) => !allNewNotificationIds.includes(i.id))
      );
    }
    dispatch({
      type: ActionTypes.SET_INCOMING_CALLS_NOTIFICATIONS,
      incomingCallsNotifications: allNotifications,
    });
  };
}

export function setAcceptedCall(acceptedCall) {
  return {
    type: ActionTypes.SET_ACCEPTED_CALL,
    acceptedCall,
  };
}

export function setActiveCategoryIndex(activeCategoryIndex) {
  return {
    type: ActionTypes.SET_ACTIVE_CATEGORY_INDEX,
    activeCategoryIndex,
  };
}

export function toggleHalfSelectedSize({
  size,
  variantSelected,
  history,
  parentPage,
  selectedCategoryId,
}) {
  return (dispatch, getState) => {
    const { dashboardReducer: state } = getState();

    // const originalHalfHalf1 = state.productSetup.halfHalfs
    //   ? state.productSetup.halfHalfs[0]
    //   : {};
    let originalHalfHalf1 = {};
    if (
      state?.productSetup?.halfHalfs &&
      state?.productSetup?.halfHalfs.length > 0
    ) {
      state.productSetup.halfHalfs.map((item) => {
        item.categories.map((category) => {
          if (category._id === selectedCategoryId) {
            originalHalfHalf1 = item;
          }
        });
      });
    }
    const halfHalf1 = _generateCurrentHalfHalfData(
      originalHalfHalf1,
      state.menuItems,
      originalHalfHalf1.selectedSizes,
      size,
      variantSelected
    );

    // current half and half selections
    const currentSelections = (state.currentHalfHalfData?.halfHalfs || [])
      ?.map((item) => {
        return item?.currentMenuItem;
      })
      .filter(Boolean);

    /**
     * @description
     * firstHalfData, secondHalfData have the latest menu items
     * and other half half data for the incoming size
     */
    let firstHalfData = { ...halfHalf1, halfIndex: 1 };
    let secondHalfData = { ...halfHalf1, halfIndex: 2 };

    const payload = {
      firstHalfData,
      secondHalfData,
      variantSelected,
    };

    const resetHalfAndHalfSelectionsWithNewSize = ({ resetWhichHalf } = {}) => {
      if (resetWhichHalf === 1) {
        const { currentMenuItem, ...rest } = firstHalfData;
        const payload = {
          firstHalfData: rest,
          secondHalfData,
          variantSelected,
        };

        dispatch({
          type: ActionTypes.TOGGLE_HALF_SELECTED_SIZE,
          payload,
        });
        return;
      }

      if (resetWhichHalf === 2) {
        const { currentMenuItem, ...rest } = secondHalfData;
        const payload = {
          firstHalfData,
          secondHalfData: rest,
          variantSelected,
        };

        dispatch({
          type: ActionTypes.TOGGLE_HALF_SELECTED_SIZE,
          payload,
        });

        return;
      }

      dispatch({
        type: ActionTypes.TOGGLE_HALF_SELECTED_SIZE,
        payload,
      });

      return;
    };

    // const stateWithUpdatedCurrentHalfAndHalfItems = {
    //   ...state,
    //   currentHalfHalfData: {
    //     ...state.currentHalfHalfData,
    //     halfHalfs: [firstHalfData, secondHalfData],
    //     variantSelected
    //   }
    // }

    /**
     * @description
     *
     * update redux half and half state with current size data
     * this has to be done in any case.
     */
    resetHalfAndHalfSelectionsWithNewSize();

    const noPreviousSelectionForEitherHalf = currentSelections.length === 0;

    /**
     * if there are no previously selected half and half
     * item(s) `resetHalfAndHalfSelectionsWithNewSize` would
     * have just prepared selection from the new size's menu items
     * nothing else needs to be done
     */
    if (noPreviousSelectionForEitherHalf) {
      return;
    }

    const returnDefault = (halfIndex) => {
      if (halfIndex === 1) {
        return firstHalfData?.currentMenuItem;
      }

      if (halfIndex === 2) {
        return secondHalfData?.currentMenuItem;
      }
    };

    const newSelections = currentSelections
      .map((prevItem, index) => {
        const halfIndex = index + 1;

        const getDefaultItem = () => {
          return returnDefault(halfIndex);
        };

        if (!prevItem) return getDefaultItem();

        const incomingSizeItemCorrespondingToPreviousSizeItem =
          halfHalf1.menuItems.find(
            (i) => i._id === prevItem._id || prevItem.name === i.name
          );

        if (!incomingSizeItemCorrespondingToPreviousSizeItem) {
          return getDefaultItem();
        }

        const selectedSizeToExtractVariantsFromForIncomingItem =
          getSelectedSizeToExtractVariantsFrom(
            incomingSizeItemCorrespondingToPreviousSizeItem
          );

        const incomingVariants =
          selectedSizeToExtractVariantsFromForIncomingItem?.variants || [];
        const incomingHasVariants = incomingVariants.length;

        const isVariantSelection = !!state.currentHalfHalfData?.variantSelected;
        const idOfSelectedVariant = state.currentHalfHalfData?.variantSelected;

        let retVal = incomingSizeItemCorrespondingToPreviousSizeItem;

        if (!isVariantSelection) {
          retVal = getSingleSizedItemWithMeta(
            incomingSizeItemCorrespondingToPreviousSizeItem,
            halfIndex,
            true,
            prevItem
          );

          const withAppliedModifiers = applyModifiersIfNeedBe({
            incomingItem: retVal,
            prevItem: prevItem,
          });

          if (withAppliedModifiers) {
            return withAppliedModifiers;
          }

          return retVal;
        } else {
          if (
            incomingHasVariants &&
            incomingVariants.find(
              // ask gautham if doing this is okay
              (variant) => variant._id === idOfSelectedVariant.replace('_', ' ')
            )
          ) {
            // copy pasted from `src/components/HalfHalfMenuItems/HalfHalfMenuItemsWrapper.jsx`
            const { selectedSizes } =
              incomingSizeItemCorrespondingToPreviousSizeItem;

            const variantId = selectedSizes[0]?.variants[0]?._id;
            const updatedItem = updateSize(
              {
                ...incomingSizeItemCorrespondingToPreviousSizeItem,
                isHalf: true,
              },
              selectedSizes[0]?._id,
              variantId
            );

            retVal = {
              ...updatedItem,
              halfIndex: parseInt(halfIndex),
              isHalf: true,
              isSecondHalf: parseInt(halfIndex) === 2,
            };

            retVal = getSingleSizedItemWithMeta(
              retVal,
              halfIndex,
              false,
              prevItem
            );

            const withAppliedModifiers = applyModifiersIfNeedBe({
              incomingItem: retVal,
              prevItem: prevItem,
              variantId,
            });

            if (withAppliedModifiers) {
              return withAppliedModifiers;
            }

            return retVal;
          }
        }

        // if none of the conditions are meet
        // just return default `currentMenuItem` for each half
        return getDefaultItem();
      })
      /**
       * @transit
       * it might make sense to remove this
       * if we ensure that map is always sending
       * two non falsy values ( which it mostly is)
       * just have to super check once before removing.
       */
      .filter(Boolean);

    // for each new selection, update current half and half
    // items in the redux store.
    newSelections.forEach((item, index) => {
      dispatch(updateCurrentHalfHalfItem(item));
    });
  };
}

export function updateCurrentHalfHalfItem(menuItem) {
  return {
    type: ActionTypes.UpdateCurrentHalfHalfItem,
    menuItem,
  };
}

export function toggleHalfHalfEditMode(halfIndex) {
  return {
    type: ActionTypes.ToggleHalfHalfEditMode,
    halfIndex,
  };
}

export function resetCurrentHalfHalfData() {
  return {
    type: ActionTypes.ResetCurrentHalfHalfData,
  };
}

export function updateCurrentFourQuarterData(categoryId) {
  return {
    type: ActionTypes.UpdateCurrentFourQuarterData,
    categoryId,
  };
}

export function toggleFourQuarterSelectedSize(size) {
  return {
    type: ActionTypes.ToggleFourQuarterSelectedSize,
    size,
  };
}

export function updateCurrentFourQuarterItem(menuItem) {
  return {
    type: ActionTypes.UpdateCurrentFourQuarterItem,
    menuItem,
  };
}

export function toggleFourQuarterEditMode(fourQuarterIndex) {
  return {
    type: ActionTypes.ToggleFourQuarterEditMode,
    fourQuarterIndex,
  };
}

export function resetCurrentFourQuarterData() {
  return {
    type: ActionTypes.ResetCurrentFourQuarterData,
  };
}

export function updateSpecialsHalfHalfData(categoryIndex, specialsId) {
  return {
    type: ActionTypes.UpdateSpecialsHalfHalfData,
    categoryIndex,
    specialsId,
  };
}

export function newToggleSpecialsHalfSelectedSize({
  categoryIndex,
  size,
  variantSelected,
}) {
  return (dispatch, _getState) => {
    // const { dashboardReducer: state } = getState();

    // const originalHalfHalf = state.productSetup.halfHalfs
    //   ? state.productSetup.halfHalfs[0]
    //   : {};

    // const halfHalfSpecials = _generateCurrentHalfHalfData(
    //   originalHalfHalf,
    //   state.menuItems,
    //   originalHalfHalf.selectedSizes,
    //   size,
    //   variantSelected
    // );

    dispatch({
      type: ActionTypes.ToggleSpecialsHalfSelectedSize,
      size,
      variantSelected,
      categoryIndex,
    });
  };
}

export function toggleSpecialsHalfSelectedSize(categoryIndex, size) {
  return {
    type: ActionTypes.ToggleSpecialsHalfSelectedSize,
    size,
    categoryIndex,
  };
}

export function updateSpecialsCurrentHalfHalfItem(categoryIndex, menuItem) {
  return {
    type: ActionTypes.UpdateSpecialsCurrentHalfHalfItem,
    categoryIndex,
    menuItem,
  };
}

export function toggleSpecialsHalfHalfEditMode(categoryIndex, halfIndex) {
  return {
    type: ActionTypes.ToggleSpecialsHalfHalfEditMode,
    categoryIndex,
    halfIndex,
  };
}

export function resetSpecialsCurrentHalfHalfData(categoryIndex) {
  return {
    type: ActionTypes.ResetSpecialsCurrentHalfHalfData,
    categoryIndex,
  };
}

export function updateSpecialsFourQuarterData(categoryIndex, specialsId) {
  return {
    type: ActionTypes.UpdateSpecialsFourQuarterData,
    categoryIndex,
    specialsId,
  };
}

export function updateSpecialsCurrentFourQuarterItem(categoryIndex, menuItem) {
  return {
    type: ActionTypes.UpdateSpecialsCurrentFourQuarterItem,
    categoryIndex,
    menuItem,
  };
}

export function toggleSpecialsFourQuarterEditMode(
  categoryIndex,
  fourQuarterIndex
) {
  return {
    type: ActionTypes.ToggleSpecialsFourQuarterEditMode,
    categoryIndex,
    fourQuarterIndex,
  };
}

export function toggleSpecialsFourQuarterSelectedSize(categoryIndex, size) {
  return {
    type: ActionTypes.ToggleSpecialsFourQuarterSelectedSize,
    size,
    categoryIndex,
  };
}

export function resetSpecialsCurrentFourQuarterData(categoryIndex) {
  return {
    type: ActionTypes.ResetSpecialsCurrentFourQuarterData,
    categoryIndex,
  };
}

export function authenticateStaff(flag) {
  return {
    type: ActionTypes.AuthenticateStaff,
    flag,
  };
}

export const setOrderTypeConfig = (orderTypeConfig) => {
  return {
    type: ActionTypes.SET_ORDER_TYPE_CONFIG,
    orderTypeConfig,
  };
};

export function setActiveStaffMember(staffDetails) {
  return {
    type: ActionTypes.SetActiveStaffMember,
    staffDetails,
  };
}

export function setGoToRouteAfterAuth(route) {
  return {
    type: ActionTypes.SetGoToRouteAfterAuth,
    route,
  };
}

export function setScreenSizes(sizes) {
  return {
    type: ActionTypes.ScreenSizes,
    sizes,
  };
}

export function setCashAmountChange(cashAmountChange) {
  return {
    type: ActionTypes.cashAmountChange,
    cashAmountChange,
  };
}

export function updateCashModalTitle(title) {
  return {
    type: ActionTypes.UpdateCashModalTitle,
    title,
  };
}

export function setCashPaid(cashPaid) {
  return {
    type: ActionTypes.cashPaid,
    cashPaid,
  };
}

export function getDiscountAction(requestObj) {
  return function (dispatch) {
    return getDiscounts(requestObj)
      .then((data) => {
        if (data?.discounts && data.discounts.length > 0) {
          dispatch({
            type: ActionTypes.GET_DISCOUNT_SUCCESS,
            payload: data.discounts,
          });
        } else {
          dispatch({
            type: ActionTypes.GET_DISCOUNT_FAILED,
          });
        }
      })
      .catch((e) => {
        dispatch({
          type: ActionTypes.GET_DISCOUNT_FAILED,
        });
      });
  };
}

export function getVoucherAction(requestObj) {
  return function (dispatch) {
    return getVouchers(requestObj).then((data) =>
      dispatch({
        type: ActionTypes.GET_VOUCHER_SUCCESS,
        payload: data.vouchers,
      })
    );
  };
}

export function setOrderBackModal(setModal) {
  return {
    type: ActionTypes.SET_ORDER_BACK_MODAL,
    setModal,
  };
}

export function setDisableSpecialDiscountModal(setModal) {
  return {
    type: ActionTypes.SET_DISABLE_SPECIAL_DISCOUNT_MODAL,
    setModal,
  };
}

export function setOrderPayments(orderPayments) {
  return {
    type: ActionTypes.SET_ORDER_PAYMENTS,
    orderPayments,
  };
}

export function setSplitOrderSessionId(splitOrderSessionId) {
  return {
    type: ActionTypes.SET_SPLIT_ORDER_SESSION_ID,
    splitOrderSessionId,
  };
}

export function setSplitOrderId(splitOrderId) {
  return {
    type: ActionTypes.SET_SPLIT_ORDER_ID,
    splitOrderId,
  };
}

export function setSplitOrderServiceId(splitOrderServiceId) {
  return {
    type: ActionTypes.SET_SPLIT_ORDER_SERVICE_ID,
    splitOrderServiceId,
  };
}

// ----------UTIL FUNCS-----------------

function getSelectedSizeToExtractVariantsFrom(item) {
  return item.selectedSizes?.find(
    (size) =>
      size._id === size?._id ||
      size.name.toLowerCase() === size?.name?.toLowerCase()
  );
}

function applyModifiersIfNeedBe({ incomingItem, prevItem, variantId } = {}) {
  const prevModifiersOtherThanSize =
    prevItem?.selectedModifiers?.filter(
      (modifier) => modifier.name !== 'size'
    ) || [];

  const prevActiveModifierMap = prevModifiersOtherThanSize?.reduce(
    (acc, modifier) => {
      const isSomeActiveModifier = modifier.subModifiers.some(
        (subModifier) => subModifier.prices?.[0].quantity
      );

      if (isSomeActiveModifier) {
        acc[modifier._id] = {
          name: modifier.name,
          activeSubModifiers: {},
        };
      }

      modifier.subModifiers.forEach((subModifier) => {
        if (subModifier.prices[0].quantity === 0.5) {
          acc[modifier._id].activeSubModifiers[subModifier._id] = {
            name: subModifier.name,
          };
        }
      });

      return acc;
    },
    {}
  );

  /**
   * if incoming has modifiers
   * check if incoming has ALL those modifiers
   *
   * if incoming has a subset of active modifiers
   * apply them
   *
   * if incoming has a missing modifier from the adtive modifiers
   * reset with only variant applied
   *
   * separately check for variant as well
   */

  const incomingModifiersData = incomingItem?.selectedModifiers || [];
  const incomingAllModifierKeys = incomingModifiersData?.map((i) => i.name);
  const prevActiveModifierKeys = Object.keys(prevActiveModifierMap).map(
    (modifierId) => prevActiveModifierMap[modifierId].name
  );

  const allIncomingModifiersAreInPrevActiveModifiers = incomingAllModifierKeys
    .filter((key) => key !== 'size')
    .every((key) => prevActiveModifierKeys.includes(key));

  const haveToApplyModifiers =
    Object.keys(prevActiveModifierKeys).length > 0 &&
    allIncomingModifiersAreInPrevActiveModifiers;

  if (haveToApplyModifiers) {
    // for each incoming modifier

    let aggregator = { ...incomingItem, isHalf: true };
    incomingAllModifierKeys.forEach((modifierName) => {
      // if previous had an active entry for this modifier

      if (modifierName === 'size') {
        const selectedItemBaseSubModifierId =
          getItemBaseSubModifierId(aggregator);

        const modifierId = incomingModifiersData.find(
          (modifier) => modifier.name === 'size'
        )?._id;
        const subModifierId = incomingModifiersData.find(
          (modifier) => modifier.name === 'size'
        )?.subModifiers[0]._id;

        let currentItemObj = updateModifier(
          aggregator,
          subModifierId,
          modifierId,
          variantId || 'xxx',
          selectedItemBaseSubModifierId
        );

        aggregator = currentItemObj;
        return;
      }

      const thisModifierHasActivePrevSubModifier = Object.keys(
        prevActiveModifierMap
      ).find((modifierId) => {
        return (
          prevActiveModifierMap[modifierId].name === modifierName &&
          Object.keys(prevActiveModifierMap[modifierId].activeSubModifiers)
            .length > 0
        );
      });

      if (thisModifierHasActivePrevSubModifier) {
        const selectedItemBaseSubModifierId =
          getItemBaseSubModifierId(aggregator);
        const modifierId = thisModifierHasActivePrevSubModifier;
        const subModifierId = Object.keys(
          prevActiveModifierMap[modifierId].activeSubModifiers
        )[0];

        let currentItemObj = updateModifier(
          aggregator,
          subModifierId,
          modifierId,
          variantId || 'xxx',
          selectedItemBaseSubModifierId
        );

        aggregator = currentItemObj;
      }
    });

    return aggregator;
  }
}

export function setCurrentMenuItemNotes(itemNotes) {
  return {
    type: ActionTypes.SET_CURRENT_MENU_ITEM_NOTES,
    itemNotes,
  };
}

export function setCurrentHalfHalfNotes(itemNotes) {
  return {
    type: ActionTypes.SET_CURRENT_HALF_HALF_NOTES,
    itemNotes,
  };
}

export function setOpenItemNotesModal(openItemNotesModal) {
  return {
    type: ActionTypes.OPEN_ITEM_NOTES_MODAL,
    openItemNotesModal,
  };
}

export function setRefreshToUpdatePosVersion(shouldRefresh) {
  return {
    type: ActionTypes.REFRESH_TO_UPDATE_POS_VERSION,
    shouldRefresh,
  };
}

export function setPaymentTypes(paymentTypes) {
  return {
    type: ActionTypes.SET_PAYMENT_TYPES,
    paymentTypes,
  };
}

function getPublicHolidaysPromise(
  timeZone,
  stateIdentifier,
  countryIdentifier,
  storeId
) {
  let days = [];
  let today = getCurrentMomentDate(timeZone);
  days.push(today.format('YYYY-MM-DD'));
  for (var i = 1; i < 10; i++) {
    days.push(today.add(1, 'days').format('YYYY-MM-DD'));
  }
  return Promise.all(
    days.map((m) => {
      return _firestore
        .collection('PublicHolidays')
        .where('date', '==', m)
        .where('countryIdentifier', '==', countryIdentifier.toUpperCase())
        .where('stateList', 'array-contains-any', [
          stateIdentifier.toUpperCase(),
          storeId,
          'ALL',
        ])
        .get()
        .then((querySnapshot) => {
          return _firestore
            .collection('PublicHolidayOptouts')
            .where('date', '==', m)
            .where('storeId', '==', storeId)
            .get()
            .then((querySnapshot1) => {
              if (
                querySnapshot.docs.length > 0 &&
                querySnapshot1.docs.length <= 0
              ) {
                return m;
              }
            });
        });
    })
  );
}

export function toggleOrderModal(bool) {
  return {
    type: actionTypes.toggleOrderModal,
    payload: bool,
  };
}

export const storeAccessToken = (accessToken) => ({
  type: 'STORE_ACCESS_TOKEN',
  accessToken,
});

export const clearAccessToken = () => ({
  type: 'CLEAR_ACCESS_TOKEN',
});

export const setActiveSeatId = (activeSeatId) => {
  return {
    type: actionTypes.SET_ACTIVE_SEAT_ID,
    activeSeatId,
  };
};
