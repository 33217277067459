export const cardTypeMappings = {
  jcb: 1,
  amex: 2,
  americanexpress: 2,
  dinnersclub: 3,
  discover: 4,
  mastercard: 5,
  master: 5,
  visa: 6,
  unionpay: 7,
};

const getCreditCardsCharges = ({
  creditCardsCharges = [],
  cardType = 5,
  isInternationCard = false,
}) => {
  let percentageCharge = 1.75;
  let fixedCharge = 0.3;
  let card = creditCardsCharges?.find(
    (card) => Number(card.cardType) === cardType
  );

  if (card) {
    if (isInternationCard) {
      percentageCharge =
        card?.processingFeesInternationCard || percentageCharge;
      fixedCharge =
        card?.additionalOnlineProcessingChargeInternationCard || fixedCharge;
    } else {
      percentageCharge = card?.processingFees || percentageCharge;
      fixedCharge = card?.additionalOnlineProcessingCharge || fixedCharge;
    }
  }
  return { percentageCharge, fixedCharge };
};

export default getCreditCardsCharges;
