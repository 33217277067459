import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import Lottie from 'react-lottie';
import checkMarkSuccess from '../../assets/lotties/782-check-mark-success.json';
import paymentFailed from '../../assets/lotties/16305-payment-failed.json';
import { _auth } from '../../firebase';
import GeneralModal from '../../modals/GeneralModal/index-new';
import Button from '../Button';
import { PosIconLeftChevronSecond } from '../../assets/icons/PosIconLeftChevronSecond';
import { PosIconCreditCard } from '../../assets/icons/PosIconCreditCard';
import masterCard from '../../assets/img/pay_master.svg';
import visaPay from '../../assets/img/pay_visa.svg';
import amexPay from '../../assets/img/pay_amex.svg';
import clsx from 'clsx';
import {
  currencyCodeSelector,
  currencySymbolSelector,
  storeConfigSelector,
  storeCountryIdentifierSelector,
} from '../../pages/Dashboard/selectors';
import {
  authenticateStaff,
  resetCurrentOrder,
} from '../../pages/Dashboard/action';
import { PosIconLoading } from '../../assets/icons/PosIconLoading';
import valid from 'card-validator';
import { prettyCardNumber } from '../../util/prettyCardNumber';
import { toast } from 'react-toastify';
import { getAmountWithCountryCurrency } from '../../util/currencyFormat';
import { useHistory } from 'react-router';
import cardValidator from 'card-validator';
import getCreditCardsCharges, {
  cardTypeMappings,
} from './CustomerPaymentDetailsModal.utils';

export const CustomerPaymentDetailsModal = ({
  modalStatus,
  toggleModal,
  currentOrder,
  orderPayments,
  orderTimes,
}) => {
  const history = useHistory();
  const storeConfig = useSelector(storeConfigSelector);
  const supportedType = ['visa', 'mastercard', 'american-express'];
  const [preventClosing, setPreventClosing] = useState(false);

  const [creditCardChargesValue, setCreditCardChargesValue] = useState('0');
  const [isPlacingOrder, setIsPlacingOrder] = useState(false);
  const [payableAmount, setPayableAmount] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [paymentAnimation, setPaymentAnimation] = useState(0);
  const dispatch = useDispatch();
  const nextOrderConfigURL = useSelector(
    (state) => state.dashboardReducer.nextOrderConfigURL
  );

  const [cardNumber, setCardNumber] = useState('');
  const [cardNumberFormatted, setCardNumberFormatted] = useState('');
  const [isCardValid, setIsCardValid] = useState(false);
  const [expiryDate, setExpiryDate] = useState('');
  const [expiryMonth, setExpiryMonth] = useState(0);
  const [expiryYear, setExpiryYear] = useState(0);
  const [isExpiryDateValid, setExpiryDateValid] = useState(false);
  const [cardCvv, setCVV] = useState('');
  const [isCVVValid, setIsCVVValid] = useState(false);

  const [activeField, setActiveField] = useState('cardNumber');
  const [isCardValidStatus, setIsCardValidStatus] = useState(true);
  const [isCVVValidStatus, setIsCVVValidStatus] = useState(true);
  const [isExpiryDateValidStatus, setIsExpiryDateValidStatus] = useState(true);

  const [cvvLength, setCvvLength] = useState(3);
  const [cardType, setCardType] = useState('');
  const [wiggleEffect, setWiggleEffect] = useState(false);
  const wiggleTimeout = useRef(0);

  const [activeKeyId, setActiveKeyId] = useState('');
  const mainRef = useRef();
  const cardRef = useRef();
  const expiryRef = useRef();
  const cvvRef = useRef();
  const timeout = useRef(0);
  const keyTimeout = useRef(0);

  const currencyCode = useSelector(currencySymbolSelector);
  const storeCountryIdentifier = useSelector(storeCountryIdentifierSelector);

  const creditCardsCharges = useSelector(
    (state) => state.dashboardReducer.creditCardsCharges
  );

  const keyList = useRef([
    { id: 1, name: '1', val: '1' },
    { id: 2, name: '2', val: '2' },
    { id: 3, name: '3', val: '3' },
    { id: 4, name: '4', val: '4' },
    { id: 5, name: '5', val: '5' },
    { id: 6, name: '6', val: '6' },
    { id: 7, name: '7', val: '7' },
    { id: 8, name: '8', val: '8' },
    { id: 9, name: '9', val: '9' },
    {
      id: 10,
      name: ' ',
      val: ' ',
    },
    { id: 11, name: '0', val: '0' },
    {
      id: 13,
      name: <PosIconLeftChevronSecond mainColor="#515151" />,
      val: 'Clear',
    },
  ]);

  const handleCVVChange = async (fieldVal) => {
    let cvvValue = fieldVal.replace(/\D/g, '');

    if (cvvValue.length <= cvvLength) {
      let cvvValidation = valid.cvv(cvvValue, cvvLength);

      if (cvvValidation.isPotentiallyValid) {
        setIsCVVValidStatus(true);

        if (cvvValidation.isValid) {
          setIsCVVValid(true);
        } else {
          setIsCVVValid(false);
        }
      } else {
        setIsCVVValidStatus(false);
        setIsCVVValid(false);
      }

      setCVV(cvvValue);
      setPaymentAnimation(0);
      setErrorMessage('');
    }
  };

  const handleCardExpiryChange = async (fieldVal) => {
    let expiryDateStr = fieldVal.replace(/[^0-9^/]/g, '');
    let dateStr = '';

    if (expiryDateStr.length <= 5) {
      if (expiryDateStr.length === 1) {
        let reg = /[2-9]/g;

        if (reg.test(expiryDateStr)) {
          dateStr = `0${expiryDateStr}/`;
        } else {
          dateStr = expiryDateStr;
        }
      } else if (expiryDateStr.length === 2) {
        // rules for automatic addition (delete) of a slash
        let reg = /^(0[1-9]|1[0-2])$/g;

        if (expiryDate.length === 1) {
          if (reg.test(expiryDateStr)) {
            dateStr = expiryDateStr + '/';
          } else {
            dateStr = '';
          }
        } else if (expiryDate.length === 3) {
          dateStr = expiryDateStr.substring(0, 1);
        }
      } else {
        dateStr = expiryDateStr;
      }

      if (dateStr.length === 5) {
        let expiryDatValidation = valid.expirationDate(dateStr);

        if (expiryDatValidation.isValid) {
          setExpiryDateValid(true);
          setIsExpiryDateValidStatus(true);

          let month = expiryDatValidation.month
            ? expiryDatValidation.month
            : '';
          let year = expiryDatValidation.year ? expiryDatValidation.year : '';

          setExpiryMonth(Number(month));
          setExpiryYear(Number(year));

          if (!isCVVValid) {
            cvvRef.current.focus();
          }
        } else {
          setExpiryDateValid(false);
          setIsExpiryDateValidStatus(false);
        }
      } else {
        setExpiryDateValid(false);
        setIsExpiryDateValidStatus(true);
      }

      setExpiryDate(dateStr);
      setPaymentAnimation(0);
      setErrorMessage('');
    }
  };

  const handleCardChange = async (fieldVal) => {
    let cardValue = fieldVal.replace(/\D/g, '');
    let numberValidation = valid.number(cardValue);
    let type = numberValidation.card?.type ? numberValidation.card.type : '';
    let cvv = numberValidation.card?.code.size
      ? numberValidation.card.code.size
      : 3;
    let cardLengths =
      numberValidation.card?.lengths.length > 0
        ? numberValidation.card.lengths
        : [];
    let defCardLength = 16;
    let maxCardLength =
      cardLengths.length > 0 ? getMaxOfArray(cardLengths) : defCardLength;

    if (cardValue.length > 0 && cardValue.length <= maxCardLength) {
      if (type && supportedType.includes(type)) {
        let startCheck =
          cardLengths.length > 0 && cardLengths.includes(cardValue.length);

        cardValue = prettyCardNumber(cardValue, numberValidation.card?.gaps);

        if (numberValidation.isPotentiallyValid) {
          setIsCardValidStatus(true);

          if (numberValidation.isValid) {
            setIsCardValid(true);
            setCardNumber(cardValue.replace(/\s/g, ''));

            if (!isExpiryDateValid) {
              expiryRef.current.focus();
            }
          } else {
            setIsCardValid(false);

            if (startCheck) {
              setIsCardValidStatus(false);
            }
          }
        } else {
          setIsCardValidStatus(false);
          setIsCardValid(false);
        }

        setCardType(type);
        setCvvLength(cvv);
      } else {
        // unsupported card type
        cardValue = prettyCardNumber(cardValue, [4, 8, 12]);
        setIsCardValid(false);
        setCardType('');
        setCvvLength(3);

        if (cardValue.length <= 3) {
          setIsCardValidStatus(true);
        } else {
          setIsCardValidStatus(false);
        }
      }

      setCardNumberFormatted(cardValue);
    } else if (cardValue.length === 0) {
      //reset data
      setCardNumberFormatted('');
      setCardNumber('');
      setCardType('');
      setCvvLength(3);
      setIsCardValid(false);
    }

    //reset animation
    setPaymentAnimation(0);
    setErrorMessage('');
  };

  const handleOrderSubmit = (event) => {
    setActiveField('');

    //Place order here now
    if (isPlacingOrder) {
      return;
    }

    if (currentOrder && orderTimes) {
      let currentOd = {
        ...currentOrder,
      };

      currentOd.paymentType = '2';

      if (currentOd.selectedVoucher) delete currentOd.selectedVoucher;

      // if (currentOd.selectedDiscount) delete currentOd.selectedDiscount;

      let requestObj = {
        order: {
          ...currentOd,
          //storeId: storeConfig?.storeId,
          countryIdentifier: storeConfig.countryIdentifier,
          doNotUpdateOrderStatus: false,
          payableAmount: (
            Number(payableAmount) + parseFloat(creditCardChargesValue)
          ).toFixed(2),
          creditCardCharges: creditCardChargesValue,
          cardDescription: cardType,
          cardType: `${cardTypeMappings[cardType?.toLowerCase()]}`,
          //restaurantId: storeConfig.restaurantId,
        },
        payment: {
          amount: parseInt(
            (
              (Number(payableAmount) + parseFloat(creditCardChargesValue)) *
              100
            ).toFixed(2),
            10
          ),
          card: {
            number: cardNumber,
            exp_month: expiryMonth,
            exp_year: expiryYear,
            cvc: cardCvv,
          },
        },
      };
      setIsPlacingOrder(true);
      if (requestObj?.order['idempotent-key']) {
        delete requestObj?.order['idempotent-key'];
      }
      const idempotentKey = currentOd['idempotent-key'];
      // MOTO Payments
      fetch(`${nextOrderConfigURL}/placeOrder3DSecure`, {
        method: 'post',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          'idempotent-key': idempotentKey,
        },
        body: JSON.stringify({
          ...requestObj,
        }),
      })
        .then((response) => {
          return response.json();
        })
        .then((result) => {
          if (result.success) {
            // handleTerminalAnimation(event);
            loadStripe(storeConfig.publishableKey).then((stripe) => {
              stripe
                .confirmCardPayment(result.payload.secret, {
                  setup_future_usage: 'on_session',
                })
                .then((res) => {
                  if (
                    res.paymentIntent &&
                    res.paymentIntent.status === 'succeeded'
                  ) {
                    setPaymentAnimation(1);
                    setErrorMessage('');
                    setIsPlacingOrder(false);
                    setPreventClosing(false);
                    let timer2 = setTimeout(function () {
                      toggleModal();
                      if (currentOrder.isEditOrder || currentOrder.iteration) {
                        history.push('/orders');
                      } else {
                        dispatch(authenticateStaff(false));
                      }
                      dispatch(resetCurrentOrder());
                      clearTimeout(timer2);
                    }, 2000);
                  } else if (res.error) {
                    let message = res.error.message;
                    if (
                      res.error.code &&
                      res.error.code === 'card_declined' &&
                      res.error.decline_code &&
                      res.error.decline_code === 'insufficient_funds'
                    ) {
                      message = 'Your card has been declined';
                    }
                    setPaymentAnimation(2);
                    toast.error(message);
                    // setErrorMessage(message);
                    setIsPlacingOrder(false);
                    setPreventClosing(false);
                  }
                });
            });
          } else {
            // setErrorMessage('Error placing an error');
            toast.error('Error placing the order, please try again');
            setPaymentAnimation(2);
            setIsPlacingOrder(false);
            setPreventClosing(false);
          }
        });
    }
  };

  const checkMarkSuccessLottie = {
    loop: false,
    autoplay: true,
    animationData: checkMarkSuccess,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const paymentFailedLottie = {
    loop: false,
    autoplay: true,
    animationData: paymentFailed,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const handleKeyDown = async (e) => {
    if (e !== undefined && e.keyCode !== undefined) {
      let keyVal = '';
      const keyPlusList = [107, 187];
      const keyDelList = [8, 46];
      const keyNumList = [
        48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 96, 97, 98, 99, 100, 101, 102,
        103, 104, 105,
      ];

      if (keyDelList.includes(Number(e.keyCode || e.which))) {
        keyVal = 'clear';
      } else if (keyPlusList.includes(Number(e.keyCode || e.which))) {
        keyVal = '+';
      } else if (keyNumList.includes(Number(e.keyCode || e.which))) {
        keyVal = e.key;
      }

      if (keyVal) {
        if (keyList.current.length > 0) {
          keyList.current.forEach((item, i) => {
            if (item.val.toString().toLowerCase() === keyVal.toString()) {
              setActiveKeyId(item.id);
            }
          });
        }

        await writePhone(keyVal);
      }
    }
  };

  const writePhone = async (val) => {
    if (!preventClosing) {
      let str =
        activeField === 'cardNumber'
          ? cardNumberFormatted
          : activeField === 'cardExpiry'
          ? expiryDate
          : activeField === 'cardCvv'
          ? cardCvv
          : '';

      if (val.toLowerCase() === 'clear') {
        if (str.length === 0) {
          if (activeField === 'cardExpiry') {
            cardRef.current.focus();
          } else if (activeField === 'cardCvv') {
            expiryRef.current.focus();
          }
        }

        str = str.slice(0, -1);
      } else {
        str += val;
      }

      if (activeField === 'cardNumber') {
        await handleCardChange(str);
      } else if (activeField === 'cardExpiry') {
        await handleCardExpiryChange(str);
      } else if (activeField === 'cardCvv') {
        await handleCVVChange(str);
      }
    }
  };

  const handleFocus = (e, filedName) => {
    if (filedName) {
      setActiveField(filedName);
    }
  };

  const resetPaymentsForm = () => {
    setCVV('');
    setCardNumberFormatted('');
    setCardNumber('');
    setExpiryDate('');
    setExpiryMonth(0);
    setExpiryYear(0);
    setIsCardValid(false);
    setExpiryDateValid(false);
    setIsCVVValid(false);
    setIsCardValidStatus(true);
    setIsCVVValidStatus(true);
    setIsExpiryDateValidStatus(true);
    setPaymentAnimation(0);
    setErrorMessage('');
    setCreditCardChargesValue('0');
    cardRef.current.focus();
  };

  //when entered wrong passcode need use wiggle effect
  useEffect(() => {
    if (wiggleEffect) {
      wiggleTimeout.current = setTimeout(() => {
        setWiggleEffect(false);
      }, 850);
    }

    return () => clearTimeout(wiggleTimeout.current);
  }, [wiggleEffect]);

  useEffect(() => {
    modalStatus && mainRef.current.focus();
    timeout.current = setTimeout(() => {
      cardRef.current.focus();
    }, 200);

    return () => {
      clearTimeout(timeout.current);
    };
  }, [modalStatus]);

  useEffect(() => {
    keyTimeout.current = setTimeout(() => {
      setActiveKeyId('');
    }, 100);
  }, [cardCvv, expiryDate, cardNumberFormatted]);

  useEffect(() => {
    let alreadyPaidAmount = 0;
    if (currentOrder?.payments && currentOrder?.payments?.length > 0) {
      currentOrder?.payments.forEach((payment) => {
        alreadyPaidAmount = alreadyPaidAmount + Number(payment.amount);
      });
    }
    if (Number(currentOrder.payableAmount) < alreadyPaidAmount) {
      setPayableAmount(alreadyPaidAmount - Number(currentOrder.payableAmount));
    } else {
      setPayableAmount(Number(currentOrder.payableAmount) - alreadyPaidAmount);
    }
  }, [currentOrder, currentOrder?.payments]);

  useEffect(() => {
    if (isCardValid && isCVVValid && isExpiryDateValid) {
      let creditCardChargesAmount = 0;
      const cardType =
        cardTypeMappings[
          cardValidator.number(cardNumber)?.card?.type?.toLowerCase()
        ];

      const { percentageCharge, fixedCharge } = getCreditCardsCharges({
        creditCardsCharges: creditCardsCharges,
        cardType: cardType,
      });

      creditCardChargesAmount =
        parseFloat(payableAmount) * (percentageCharge / 100) + fixedCharge;
      creditCardChargesAmount = Number(creditCardChargesAmount).toFixed(2);

      setCreditCardChargesValue(creditCardChargesAmount);
    }
  }, [
    cardNumber,
    expiryMonth,
    expiryYear,
    cardCvv,
    isCardValid,
    isCVVValid,
    isExpiryDateValid,
  ]);

  return (
    <GeneralModal
      id="customer-phone-modal"
      modalStatus={modalStatus}
      toggleModal={preventClosing ? () => {} : () => toggleModal()}
      // position="bottom"
      effect="move"
      overlay=""
      boxWidth="435px"
      // boxHeight="692px"
      className="customer-phone-modal customer-payment-details"
      closeBtn="inside"
    >
      <div
        ref={mainRef}
        tabIndex="0"
        onKeyDown={handleKeyDown}
        className="customer-phone-modal-box"
      >
        <div className="customer-phone-header">
          <div className="payment-card-box">
            <div className="payment-row row-header">
              <div>
                <h2 className="customer-phone-title">{`Pay ${getAmountWithCountryCurrency(
                  {
                    amount: Number(payableAmount),
                    locale: `en-${storeCountryIdentifier}`,
                    currencyCode,
                  }
                )}`}</h2>
              </div>

              <div>
                <div className="payment-services-box">
                  <div className="single-payment-service mastercard">
                    <img src={masterCard} alt="Mastercard" />
                  </div>
                  <div className="single-payment-service visa">
                    <img src={visaPay} alt="Visa" />
                  </div>
                  <div className="single-payment-service amex">
                    <img src={amexPay} alt="Amex" />
                  </div>
                </div>
              </div>
            </div>
            {parseFloat(creditCardChargesValue) > 0 && (
              <span style={{ paddingLeft: 5, color: '#28A745' }}>
                +{currencyCode}
                {creditCardChargesValue} Credit Card Charge
              </span>
            )}
          </div>
          <div className="payment-error-message">
            {errorMessage ? '*' + errorMessage : ''}
          </div>
        </div>

        <div className="payment-card-box">
          <div className="payment-row row-number">
            <div>
              <label
                htmlFor="cardNumber"
                className={clsx(
                  !isCardValidStatus && 'error',
                  activeField === 'cardNumber' && 'active'
                )}
              >
                Card Number
                <input
                  id="cardNumber"
                  type="text"
                  className={clsx(
                    'input-style white',
                    !isCardValidStatus && 'wiggle-error'
                  )}
                  style={{
                    width: 298,
                  }}
                  name="cardNumber"
                  placeholder="0000 0000 0000 0000"
                  value={cardNumberFormatted}
                  autoComplete="off"
                  readOnly
                  ref={cardRef}
                  onFocus={(e) => handleFocus(e, 'cardNumber')}
                />
              </label>
            </div>
            <div className="card-preview">
              {cardType === 'visa' && isCardValid ? (
                <div className="single-payment-service visa">
                  <img src={visaPay} alt="Visa" />
                </div>
              ) : cardType === 'mastercard' && isCardValid ? (
                <div className="single-payment-service mastercard">
                  <img src={masterCard} alt="Mastercard" />
                </div>
              ) : cardType === 'american-express' && isCardValid ? (
                <div className="single-payment-service amex">
                  <img src={amexPay} alt="Amex" />
                </div>
              ) : (
                <PosIconCreditCard
                  mainColor="#979797"
                  darkModeColor="#6c6c6c"
                />
              )}
            </div>
          </div>
          <div className="payment-row row-other">
            <div>
              <label
                htmlFor="cardExpiry"
                className={clsx(
                  !isExpiryDateValidStatus && 'error',
                  activeField === 'cardExpiry' && 'active'
                )}
              >
                Expiry
                <input
                  id="cardExpiry"
                  type="text"
                  className={clsx(
                    'input-style white',
                    !isExpiryDateValidStatus && 'wiggle-error'
                  )}
                  style={{ maxWidth: 100 }}
                  name="cardExpiry"
                  placeholder="MM/YY"
                  value={expiryDate}
                  autoComplete="off"
                  ref={expiryRef}
                  readOnly
                  onFocus={(e) => handleFocus(e, 'cardExpiry')}
                />
              </label>
            </div>
            <div>
              <label
                htmlFor="cardCvv"
                className={clsx(
                  !isCVVValidStatus && 'error',
                  activeField === 'cardCvv' && 'active'
                )}
              >
                CVV
                <input
                  id="cardCvv"
                  type="text"
                  className={clsx(
                    'input-style white',
                    !isCVVValidStatus && 'wiggle-error'
                  )}
                  style={{ maxWidth: 70 }}
                  name="cardCvv"
                  placeholder="123"
                  value={cardCvv}
                  autoComplete="off"
                  ref={cvvRef}
                  readOnly
                  onFocus={(e) => handleFocus(e, 'cardCvv')}
                />
              </label>
            </div>
          </div>
        </div>

        <div className="customer-phone-keypad-box">
          {keyList.current.map((item) => {
            const itemCls = clsx(
              'customer-phone-key',
              'hover-highlight',
              activeKeyId === item.id && 'active',
              [`key-${item.val.toLowerCase()}`]
            );

            return (
              <div key={item.id} className="customer-phone-key-wrap">
                <Button
                  title={item.val}
                  className={itemCls}
                  onClick={(e) => writePhone(item.val)}
                >
                  {item.name}
                </Button>
              </div>
            );
          })}
        </div>
        <div className="customer-phone-btn-box">
          {paymentAnimation === 0 && !isPlacingOrder ? (
            <Button
              disabled={
                isPlacingOrder ||
                !isCardValid ||
                !isCVVValid ||
                !isExpiryDateValid
              }
              className="btn"
              title={`Pay ${getAmountWithCountryCurrency({
                amount:
                  Number(payableAmount) + parseFloat(creditCardChargesValue),
                locale: `en-${storeCountryIdentifier}`,
                currencyCode,
              })}`}
              onClick={(event) => handleOrderSubmit(event)}
            >
              {`Pay ${getAmountWithCountryCurrency({
                amount:
                  Number(payableAmount) + parseFloat(creditCardChargesValue),
                locale: `en-${storeCountryIdentifier}`,
                currencyCode,
              })}`}
            </Button>
          ) : null}
          {paymentAnimation === 0 && isPlacingOrder ? (
            <PosIconLoading style={{ height: 63 }} mainColor="#5eb602" />
          ) : null}
          {paymentAnimation === 1 ? (
            <Lottie
              style={{
                position: 'relative',
                left: '-5px',
              }}
              options={checkMarkSuccessLottie}
              height={63}
              width={50}
              eventListeners={[
                {
                  eventName: 'complete',
                  callback: () => {},
                },
              ]}
            />
          ) : null}
          {paymentAnimation === 2 ? (
            <button
              type="button"
              style={{
                display: 'block',
                width: '100%',
              }}
              onClick={resetPaymentsForm}
            >
              <Lottie
                style={{
                  position: 'relative',
                  left: '-5px',
                }}
                options={paymentFailedLottie}
                height={63}
                width={50}
                eventListeners={[
                  {
                    eventName: 'complete',
                    callback: () => {},
                  },
                ]}
              />
            </button>
          ) : null}
        </div>
      </div>
    </GeneralModal>
  );
};

function getMaxOfArray(numArray) {
  return Math.max.apply(null, numArray);
}

CustomerPaymentDetailsModal.propTypes = {
  modalStatus: PropTypes.bool,
  toggleModal: PropTypes.func,
  currentOrder: PropTypes.object,
  orderTimes: PropTypes.array,
};

export default CustomerPaymentDetailsModal;
