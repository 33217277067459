import XXH from 'xxhashjs';
export const capitalizeString = (s) => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const isObjectEmpty = (obj) =>
  obj && // 👈 null and undefined check
  Object.keys(obj).length === 0 &&
  obj.constructor === Object;

export const isObjectNotEmpty = (obj) => obj && Object.keys(obj).length !== 0;

export const isEmpty = (obj) => obj === null || obj === '';

export const getPercentage = (percent, total) => {
  return (percent / 100) * total;
};

export const getPercentAmount = (value, total) => {
  return value / (total / 100);
};

export const getParams = (paramsObject) => {
  const params = new URLSearchParams(paramsObject);
  return params.toString();
};

export const getParamValue = (paramName) => {
  const params = new URLSearchParams(window.location.search);
  return params.get(paramName) || '';
};

export const getServiceID = (sessionId) => {
  const date = new Date();
  const unixTimestamp = date.getTime();

  const timeHash = XXH.h64(`${sessionId}`, unixTimestamp)
    .toString(16)
    .slice(0, 10);

  return timeHash;
};

export const getNameAlphabetsForAvatar = (fullName) => {
  if (fullName) {
    const fullNameWords = fullName.split(' ');
    const firstNameLetter = fullNameWords[0].split('')[0];
    let lastNameletter = '';
    if (fullNameWords[1]) {
      lastNameletter = fullNameWords[1].split('')[0];
    }
    return `${firstNameLetter?.toUpperCase() || ''}${
      lastNameletter?.toUpperCase() || ''
    }`;
  }
  return '';
};

export const generateColorCode = (inputText) => {
  // Hash the input text

  if (inputText === 'Shared') {
    return '#32b900';
  }

  let hash = 0;
  for (let i = 0; i < inputText.length; i++) {
    hash = inputText.charCodeAt(i) + ((hash << 5) - hash);
  }

  // Generate muted color components
  const red = ((hash & 0xff) % 150) + 50; // Range: 50-199
  const green = (((hash >> 8) & 0xff) % 150) + 50; // Range: 50-199
  const blue = (((hash >> 16) & 0xff) % 150) + 50; // Range: 50-199

  // Convert the components to hexadecimal values
  const color = `#${red.toString(16)}${green.toString(16)}${blue.toString(16)}`;

  return color;
};

// when windcave feature is completed add country identifier is below array

export const windcaveCountriesIdentifier = ['NZ'];

export const sortByDisplayOrder = (list = []) => {
  return list.sort((a, b) => {
    return Number(a.displayOrder) - Number(b.displayOrder);
  });
};
